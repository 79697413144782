import React from "react";
// import { Link } from "react-router-dom";
import SideBar from "./SideBar";

const Dashboard = () => {
  return (
      <div className="flex">
        <SideBar />
        <div className="p-10">
            {/* <Link className="pl-5 pr-5 pt-3 pb-3 bg-red text-white capitalize font-semibold text-sm rounded">
                social contribution
            </Link>   */}
        </div>
      </div>
  );
};

export default Dashboard;
