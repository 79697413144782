import React from "react";
import joy from "../../image/joy.jpg";

const JoyEbike = () => {
  return (
    <div className="mt-5x bg-white">
      <div className="flex flex-col items-center justify-center w-full pl-3 sm:pl-0 pr-3 sm:pr-0">
        <h1 className="font-semibold text-xl md:text-3xl text-center uppercase">
          Explore Joy e-bike
        </h1>
        <p className="font-medium pt-2 text-[10px] md:text-sm text-center" >
          Sleek, eco-friendly, and efficient. Joy eBike is the future of urban
          commuting.
        </p>
        <div className="md:w-3/6 md:h-[300px] mt-5 md:mt-10">
        <a href="/ebikes" >
          <img className="w-full" src={joy} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoyEbike;
