import React, { useEffect } from "react";
import SideBar from "../Dashboard/SideBar";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSocialCategory } from "../../../redux/action/socialCategoryAction";

const SocialContribution = () => {
  const dispatch = useDispatch();
  const { category ,loading} = useSelector((state) => state.socialCategory);
  console.log(category);
  useEffect(() => {
    dispatch(getSocialCategory());
  }, [dispatch]);

  return (
    <div className="flex w-full font-Montserrat">
      <SideBar />
      <div className="p-5 pt-10 space-x-4 w-full">
        {/* <Link
          to="/dashboard/social-contribution/category"
          className="pl-5 pr-5 pt-3 pb-3 bg-red rounded text-white font-semibold text-lg "
        >
          Social Category
        </Link> */}
        <Link
          to="/dashboard/social-contribution/allSocial-program"
          className="pl-5 pr-5 pt-3 pb-3 bg-red rounded text-white font-semibold text-lg "
        >
          Social Programs
        </Link>

        <div className="w-full mt-10">
          <div class="relative overflow-x-auto w-full">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Category Id
                  </th>
                  <th scope="col" class="px-6 py-3">
                    created date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Edit
                  </th>
                </tr>
              </thead>

              <tbody>
                {loading ? <h1>Loading...</h1> : category &&
                  category.map((cat) => {
                    return (
                      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {cat._id}
                        </th>
                        <td class="px-6 py-4">{new Date(cat.createdAt).toISOString().substring(0,10)}</td>
                        <td class="px-6 py-4">{cat.name}</td>
                        <td class="px-6 py-4">Edit</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialContribution;
