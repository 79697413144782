import {
  GET_ALL_POSITION_REQUEST,
  GET_ALL_POSITION_SUCCESS,
  GET_ALL_POSITION_FAIL,
  GET_POSITION_DETAILS_REQUEST,
  GET_POSITION_DETAILS_SUCCESS,
  GET_POSITION_DETAILS_FAIL,
  APPLY_POSITION_REQUEST,
  APPLY_POSITION_SUCCESS,
  APPLY_POSITION_FAIL,
  GET_ALL_APPLICATION_REQUEST,
  GET_ALL_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_FAIL,
  GET_CARRER_BY_ID_SUCCESS,
  GET_CARRER_BY_ID_FAIL,
  GET_CARRER_BY_ID_REQUEST,
  UPDATE_JOB_POST_REQUEST,
  UPDATE_JOB_POST_SUCCESS,
  UPDATE_JOB_POST_FAIL,
  CREATE_JOB_POST_REQUEST,
  CREATE_JOB_POST_SUCCESS,
  CREATE_JOB_POST_FAIL,
  GET_APPLICANTS_APPLIED_REQUEST,
  GET_APPLICANTS_APPLIED_SUCCESS,
  GET_APPLICANTS_APPLIED_FAIL,
  GET_JOB_CATEGORY_REQUEST,
  GET_JOB_CATEGORY_SUCCESS,
  GET_JOB_CATEGORY_FAIL,
  UPDATE_CARRER_APPLICATION_STATUS_REQUEST,
  UPDATE_CARRER_APPLICATION_STATUS_SUCCESS,
  UPDATE_CARRER_APPLICATION_STATUS_FAIL,
  CREATE_JOB_DEPARTMENT_REQUEST,
  CREATE_JOB_DEPARTMENT_SUCCESS,
  CREATE_JOB_DEPARTMENT_FAIL,
} from "../constants/carrersConstant";

// apply job
export const applyJobPost = (state = {}, action) => {
  // console.log(state)
  switch (action.type) {
    case APPLY_POSITION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case APPLY_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        applyedPosition: action.payload,
        success: action.payload,
      };

    case APPLY_POSITION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllPosition = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_POSITION_REQUEST:
    case GET_POSITION_DETAILS_REQUEST:
    case UPDATE_JOB_POST_REQUEST:
    case CREATE_JOB_POST_REQUEST:
    case GET_APPLICANTS_APPLIED_REQUEST:
    case GET_JOB_CATEGORY_REQUEST:
    case UPDATE_CARRER_APPLICATION_STATUS_REQUEST:
    case CREATE_JOB_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        allposition: action.payload,
      };

    case CREATE_JOB_POST_SUCCESS:
      return {
        ...state,
        loading: true,
        success: action.payload,
      };

    case GET_POSITION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        positionId: action.payload,
      };

    case UPDATE_JOB_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };

    case GET_APPLICANTS_APPLIED_SUCCESS:
      return {
        ...state,
        loading: false,
        allApplicant: action.payload,
      };

    case GET_JOB_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        alljobcategory: action.payload,
      };

    case UPDATE_CARRER_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusUpdate: action.payload,
      };

    case CREATE_JOB_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentCreate: action.payload
      };

    case GET_ALL_POSITION_FAIL:
    case GET_POSITION_DETAILS_FAIL:
    case UPDATE_JOB_POST_FAIL:
    case CREATE_JOB_POST_FAIL:
    case GET_APPLICANTS_APPLIED_FAIL:
    case GET_JOB_CATEGORY_FAIL:
    case UPDATE_CARRER_APPLICATION_STATUS_FAIL:
    case CREATE_JOB_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// get carrer by id
export const getCarrerbyId = (state = {}, action) => {
  switch (action.type) {
    case GET_CARRER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CARRER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        carrerId: action.payload,
      };

    case GET_CARRER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// get all applied applications

export const getAllApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        application: action.payload,
      };

    case GET_ALL_APPLICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
