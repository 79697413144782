import React, { useEffect} from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Journal } from "./JournalsObj";
import { useParams } from "react-router-dom";

const Pdfview = () => {
  // const [check, setCheck] = useState("");
  const { id } = useParams();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();



  useEffect(() => {
    // const checkPdfId = Journal && Journal.filter((item) => item.id === Number(id));
    // // console.log(checkPdfId);
    // setCheck(checkPdfId);
  }, [id]);

  return (
    <div className="bg-white text-black">
      {Journal &&
        Journal.map((item) => {
          if(item.id === Number(id)){
          return (
            <div className="h-auto">
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={item?.pdfFile && item?.pdfFile}
                  defaultScale={1.0}
                  plugins={[defaultLayoutPluginInstance]}
                  
                />
              </Worker>
            </div>
          );
        }
        return null;
        })}
    </div>
  );
};

export default Pdfview;
