// social create
export const CREATE_SOCIAL_REQUIEST = "CREATE_SOCIAL_REQUIEST"
export const CREATE_SOCIAL_SUCCESS = "CREATE_SOCIAL_SUCCESS"
export const CREATE_SOCIAL_FAIL = "CREATE_SOCIAL_FAIL"

// get all social 
export const GET_ALL_SOCIAL_REQUIEST = "GET_ALL_SOCIAL_REQUIEST"
export const GET_ALL_SOCIAL_SUCCESS = "GET_ALL_SOCIAL_SUCCESS"
export const GET_ALL_SOCIAL_FAIL = "GET_ALL_SOCIAL_FAIL"

// get social by id
export const GET_SOCIAL_BY_ID_REQUEST = "GET_SOCIAL_BY_ID_REQUEST"
export const GET_SOCIAL_BY_ID_SUCCESS = "GET_SOCIAL_BY_ID_SUCCESS"
export const GET_SOCIAL_BY_ID_FAIL = "GET_SOCIAL_BY_ID_FAIL"

// update social by id
export const UPDATE_SOCIAL_BY_REQUIEST = "UPDATE_SOCIAL_BY_REQUIEST"
export const UPDATE_SOCIAL_BY_SUCCESS = "UPDATE_SOCIAL_BY_SUCCESS"
export const UPDATE_SOCIAL_BY_FAIL = "UPDATE_SOCIAL_BY_FAIL"

// delete social by id
export const DELETE_BY_SOCIAL_BY_ID_REQUIEST = "DELETE_BY_SOCIAL_BY_ID_REQUIEST"
export const DELETE_BY_SOCIAL_BY_ID_SUCCESS = "DELETE_BY_SOCIAL_BY_ID_SUCCESS"
export const DELETE_BY_SOCIAL_BY_ID_FAIL = "DELETE_BY_SOCIAL_BY_ID_FAIL"