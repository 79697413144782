import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../redux/action/authAction";
import Login from "../../Auth/Login";

const UserProfile = () => {
  const { user, isAuthenticated, loading } = useSelector((state) => state.user);
  // const ref = useRef(null);
  // const [mobile, setMobile] = useState(user?.mobile);
  // const [name, SetName] = useState(user?.name);
  // console.log(user);
  const dispatch = useDispatch();

  const [fields, setFields] = useState([
    {
      id: 1,
      filedName: "Full Name",
      name: "name",
      value: user?.name && user?.name,
      isEditing: false,
    },
    {
      id: 2,
      filedName: "Mobile Number",
      name: "mobile",
      value: user?.mobile && user?.mobile,
      isEditing: false,
    },
  ]);

  // State to track temporary edits
  const [tempValue, setTempValue] = useState("");

  // Function to handle the edit button click
  const handleEditClick = (id) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, isEditing: true };
      }
      return field;
    });
    const fieldToEdit = fields.find((field) => field.id === id);
    setTempValue(fieldToEdit.value);
    setFields(newFields);
  };

  // Function to handle the save button click
  const handleSaveClick = (id) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, value: tempValue, isEditing: false };
      }
      console.log(field);
      return field;
    });
    setFields(newFields);
    // console.log(newFields)

    const updateValue = {
      name: newFields.find((field) => field.name === "name")?.value,
      mobile: Number(newFields.find((field) => field.name === "mobile")?.value),
    };

    // Dispatch only the name and mobile values
    dispatch(updateProfile(updateValue));
    // console.log("updateValue", typeof updateValue.mobile)
  };

  // Function to handle the cancel button click
  const handleCancelClick = (id) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, isEditing: false };
      }
      return field;
    });
    setFields(newFields);
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    setTempValue(e.target.value);
  };

  useEffect(() => {}, [dispatch]);

  return (
    <div className="bg-white text-black pb-10 sm:pb-0 w-full font-Montserrat">
      {isAuthenticated ? (
        <main class="flex flex-col sm:flex-row">
          <SideBar />
          <div className="shadow pl-2 sm:ml-3 h-[430px] sm:min-h-screen  w-full flex flex-col sm:pl-5 pt-10 pb-10">
            {loading ? (
              <h1>Loading...</h1>
            ) : (
              <div className="w-full space-y-4">
                <h1 className="w-full">
                  Created Account :{" "}
                  {new Date(user?.createdAt).toLocaleDateString()}
                </h1>

                {fields.map((field) => (
                  <div key={field.id} className="text-sm">
                    {field.isEditing ? (
                      <div>
                        <div className="flex">
                          <div className="font-semibold text-base">
                            {field.filedName}
                          </div>

                          <button
                            className="ml-5"
                            onClick={() => handleEditClick(field.id)}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="flex space-x-1 sm:space-x-3 mt-2">
                          <input
                            type="text"
                            name={field.name}
                            value={tempValue}
                            className="w[200px] sm:w-[350px] font-medium bg-white text-black  rounded pl-2 pt-1 pb-1"
                            onChange={handleInputChange}
                          />
                          <button
                            className="bg-red text-white text-sm pl-1 sm:pl-3 pr-1 sm:pr-3 pt-1 pb-1 rounded"
                            onClick={() => handleSaveClick(field.id)}
                          >
                            Save
                          </button>
                          <button
                            className="bg-red text-white text-sm pl-1 sm:pl-3 pr-1 sm:pr-3 pt-1 pb-1 rounded"
                            onClick={() => handleCancelClick(field.id)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="w-[200px] sm:w-full">
                        <div className="flex">
                          <div className="font-semibold text-sm sm:text-base">
                            {field.filedName}
                          </div>

                          <button
                            className="ml-5 font-semibold text-sm sm:text-base"
                            onClick={() => handleEditClick(field.id)}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="mt-2 w-[200px] sm:w-[350px] bg-red  font-medium bg-white text-black  rounded border border-[2px] p-2 pt-1 pb-1 ">
                          {field.value}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="w-[250px] sm:w-[450px]">
                  <div className="flex">
                    <div className="font-semibold text-sm sm:text-base">Email</div>

                    {/* <button 
                            className="ml-5"
                            onClick={() => handleEditClick(field.id)}
                          >
                            Edit
                          </button> */}
                  </div>
                  <div className="w-[330px] sm:w-auto font-medium text-base mt-2 bg-white text-black rounded pl-3 pt-1 pb-1 border border-[2px]">
                    {user?.email}
                  </div>
                </div>

                {/* <div className="flex ">
                  <h1 className="mr-3 w-[200px] font-medium text-lg ">Name</h1>
                  :
                  <input
                    className="ml-3 font-medium bg-white text-black border border-[2px] rounded pl-3 pt-1 pb-1"
                    value={name}
                    defaultValue={user?.name}
                    onChange={(e) => SetName(e.target.value)}
                  />
                  <button>edit</button>
                </div> */}
                {/* <div className="flex ">
                  <h1 className="mr-3 w-[200px] font-medium text-lg ">
                    Mobile Number
                  </h1>
                  :
                  <input
                    className="ml-3 font-medium bg-white text-black border border-[2px] rounded pl-3 pt-1 pb-1"
                    value={mobile}
                    defaultValue={user?.mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  <button>edit</button>
                </div>
                <div className="flex ">
                  <h1 className="mr-3 w-[200px] font-medium text-lg">
                    Email ID
                  </h1>{" "}
                  :
                  <h1 className="ml-3 font-medium bg-white text-black">
                    {user?.email}
                  </h1> */}
                {/* <input
                    className="ml-3 font-medium bg-white text-black"
                    value={email}
                    defaultValue={user?.email}
                    onChange={(e) => setEmail(e.target.value)}
                  /> */}
                {/* </div> */}
              </div>
            )}
          </div>
        </main>
      ) : (
        <div>
          {/* <h1></h1> */}
          {/* <Link to="/login">

          </Link> */}
          <Login />
        </div>
      )}
    </div>
  );
};

export default UserProfile;
