import axios from "axios";
import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  LOAD_USER_REQUIEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUIEST,
  GET_ALL_USERS_SUCCESS,
  USER_LOG_OUT_FAIL,
  USER_LOG_OUT_REQUEST,
  USER_LOG_OUT_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  UPDATE_PROFILE_DEATIL_FAIL,
  UPDATE_PROFILE_DEATIL_REQUEST,
  UPDATE_PROFILE_DEATIL_SUCCESS,
} from "../constants/authConstants";
import {
  GET_CARRER_APPLICATION_BY_USER_ID_FAIL,
  GET_CARRER_APPLICATION_BY_USER_ID_REQUEST,
  GET_CARRER_APPLICATION_BY_USER_ID_SUCCESS,
} from "../constants/carrersConstant";
import Cookies from "js-cookie";

export const createUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/auth/signup", user, config);
    // console.log(data);
    Cookies.set("token", data.token);

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const userLogin = (user) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/auth/login", user, config);
    Cookies.set("token", data.token);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USER_REQUIEST,
    });

    const { data } = await axios.get("/api/auth/me");
    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USERS_REQUIEST,
    });

    const { data } = await axios.get("/api/auth/allusers");
    // console.log(data);
    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all application by user id
export const getCarrerAppByUserId = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CARRER_APPLICATION_BY_USER_ID_REQUEST });

    const { data } = await axios.get("/api/carrer/my-carrers");

    dispatch({
      type: GET_CARRER_APPLICATION_BY_USER_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CARRER_APPLICATION_BY_USER_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// forgot password
export const forgotPassword = (user) => async (dispatch) => {
  try {
    console.log(user);
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/auth/forgot-password",
      user,
      config
    );
    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// reset password
export const resetPassword = (id, token, formData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.patch(
      `/api/auth/resetPassword/${id}/${token}`,
      formData,
      config
    );

    console.log(data)

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update profile
export const updateProfile = (updatePro) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROFILE_DEATIL_REQUEST,   
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.patch(
      "/api/auth/update",
      updatePro,
      config
    );
    // console.log(data)

    dispatch({
      type: UPDATE_PROFILE_DEATIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_DEATIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// logout
export const logOut = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOG_OUT_REQUEST,
    });

    await axios.post("/api/auth/logout");
    dispatch({ type: USER_LOG_OUT_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_LOG_OUT_FAIL,
      payload: error.response.data.message,
    });
  }
};
