import {
  GET_ALL_POSITION_REQUEST,
  GET_ALL_POSITION_SUCCESS,
  GET_ALL_POSITION_FAIL,
  GET_POSITION_DETAILS_REQUEST,
  GET_POSITION_DETAILS_SUCCESS,
  GET_POSITION_DETAILS_FAIL,
  APPLY_POSITION_FAIL,
  APPLY_POSITION_REQUEST,
  APPLY_POSITION_SUCCESS,
  GET_ALL_APPLICATION_FAIL,
  GET_ALL_APPLICATION_REQUEST,
  GET_ALL_APPLICATION_SUCCESS,
  GET_CARRER_BY_ID_FAIL,
  GET_CARRER_BY_ID_REQUEST,
  GET_CARRER_BY_ID_SUCCESS,
  UPDATE_JOB_POST_FAIL,
  UPDATE_JOB_POST_REQUEST,
  UPDATE_JOB_POST_SUCCESS,
  CREATE_JOB_POST_REQUEST,
  CREATE_JOB_POST_SUCCESS,
  GET_APPLICANTS_APPLIED_REQUEST,
  GET_APPLICANTS_APPLIED_SUCCESS,
  GET_APPLICANTS_APPLIED_FAIL,
  GET_JOB_CATEGORY_REQUEST,
  GET_JOB_CATEGORY_SUCCESS,
  GET_JOB_CATEGORY_FAIL,
  UPDATE_CARRER_APPLICATION_STATUS_FAIL,
  UPDATE_CARRER_APPLICATION_STATUS_REQUEST,
  UPDATE_CARRER_APPLICATION_STATUS_SUCCESS,
  CREATE_JOB_DEPARTMENT_FAIL,
  CREATE_JOB_DEPARTMENT_REQUEST,
  CREATE_JOB_DEPARTMENT_SUCCESS,
} from "../constants/carrersConstant";
import axios from "axios";

// create job
export const createJob = (createJob) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_JOB_POST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      "/api/job-position/create",
      createJob,
      config
    );

    dispatch({
      type: CREATE_JOB_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_JOB_POST_REQUEST,
      payload: error.response.data.message,
    });
  }
};

// apply for job
export const applyForJob = (jobPost) => async (dispatch) => {
  console.log(jobPost);
  try {
    dispatch({
      type: APPLY_POSITION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      "/api/carrer/carrer-create",
      jobPost,
      config
    );

    // console.log(data);

    dispatch({
      type: APPLY_POSITION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPLY_POSITION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllPositionAction = (joTitle) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_POSITION_REQUEST,
    });

    let url = `/api/job-position/all-job-position`

    if(joTitle){
      url = `/api/job-position/all-job-position?name=${joTitle}`
    }

    const { data } = await axios.get(url);
    console.log(data)

    dispatch({
      type: GET_ALL_POSITION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_POSITION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update carrer positions
export const updateCarrerStatus = (id,status) =>async(dispatch)=>{
  try {
    dispatch({
      type: UPDATE_CARRER_APPLICATION_STATUS_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.patch(`/api/carrer/allapplication/${id}`,status,config)
    dispatch({
      type: UPDATE_CARRER_APPLICATION_STATUS_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_CARRER_APPLICATION_STATUS_FAIL,
      payload: error.response.data.message
    })
  }
}

export const PositionDescription = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POSITION_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`/api/job-position/${id}`);

    dispatch({
      type: GET_POSITION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_POSITION_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all application

export const getAllApplication = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_APPLICATION_REQUEST,
    });

    const { data } = await axios.get("/api/carrer/allapplication");
    // console.log(data);
    dispatch({
      type: GET_ALL_APPLICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_APPLICATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get carrer by id
export const getCarrerByid = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CARRER_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/carrer/allapplication/${id}`);

    dispatch({
      type: GET_CARRER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CARRER_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update job post
export const updateJobPost = (id, updateJob) => async (dispatch) => {
  // console.log("id", id);
  // console.log("dataobj", updateJob);
  try {
    dispatch({
      type: UPDATE_JOB_POST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.patch(
      `/api/job-position/${id}`,
      updateJob,
      config
    );

    dispatch({
      type: UPDATE_JOB_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_JOB_POST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get number of Applicants applied for one job
export const getApplicantAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPLICANTS_APPLIED_REQUEST,
    });

    const { data } = await axios.get(`/api/carrer/get-applicant/${id}`);
    dispatch({
      type: GET_APPLICANTS_APPLIED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_APPLICANTS_APPLIED_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllJobCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_CATEGORY_REQUEST,
    });

    const { data } = await axios.get("/api/job-category");
    dispatch({
      type: GET_JOB_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_JOB_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};



// create department type
export const createDepartment = (department) =>async(dispatch)=>{
  try {
    dispatch({
      type: CREATE_JOB_DEPARTMENT_REQUEST
    })
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const {data} = await axios.post("/api/job-category/create",department,config)
    dispatch({
      type: CREATE_JOB_DEPARTMENT_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: CREATE_JOB_DEPARTMENT_FAIL,
      payload: error.response.data.message
    })
  }
}