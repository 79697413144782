import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../../redux/action/BlogsAction";
import { Link } from "react-router-dom";

const Blogs = () => {
  const dispatch = useDispatch();
  const { allblogs, loading } = useSelector((state) => state.ProductCategory);
  // console.log(allblogs);

  // console.log(allblogs);
  const truncateContent = (htmlString, wordLimit) => {
    const textContent = htmlString.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const words = textContent.split(/\s+/); // Split by whitespace
    return words.slice(0, wordLimit).join(' ') + (words.length > wordLimit ? '...' : '');
  };

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);
  return (
    <div className="font-Montserrat bg-white text-black">
      <div>
        <img src={require("../../image/Home Banner 1.png")} alt="" />
      </div>
      <div className="p-5 flex flex-wrap items-center justify-center">
        {loading ? (
          <h1>Loading...</h1>
        ) : (
          allblogs && allblogs.length > 0 ? allblogs &&
          allblogs.map((blog) => {
            // console.log(blog.content)
            return (
              <div class="max-w-sm bg-white  border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-1 sm:m-3">
                <img class="rounded-t-lg" src={blog?.image?.url} alt="" />

                <div class="p-2 sm:p-5">
                  <h5 class="mb-2 text-base sm:text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {blog.title}
                  </h5>

                  <p class="mb-3 font-normal text-xs sm:text-sm text-gray-700 dark:text-gray-400">
                    <div
                      style={{
                        fontFamily: "Montserrat",
                      }}
                      dangerouslySetInnerHTML={{ __html: truncateContent(blog.content,30) }}
                    />
                  </p>
                  <Link
                    to={`/blogs/${new Date(blog.createdAt)
                      .toISOString()
                      .substring(0, 10)}/${blog.title}`}
                    class="inline-flex items-center text-xs sm:text-sm font-medium text-center text-black bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Read more
                    <svg
                      class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            );
          })
        : <div className="pb-20 pt-20">
          <h1>Empty blog page.</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
