import React, { useEffect, useState } from "react";
import SideBar from "../../Dashboard/SideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogDetailsByID,
  updateBlogAction,
} from "../../../../redux/action/BlogsAction";
import { useParams } from "react-router-dom";
// import {
//   ContentState,
//   convertFromHTML,
//   convertToRaw,
//   EditorState,
// } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
import MyEditor from "../BlogsCreate/Editors";

const UpdateBlog = () => {
  const { blogId } = useSelector((state) => state.ProductCategory);
  const {isUpdated} = useSelector((state)=>state.blogs)
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState(blogId?.title);
  // const [category, setCategory] = useState("");
  const [image, setImageUpload] = useState();
  const [Error, setError] = useState(false);
  const [SEOTitle, setSEOTitle] = useState(blogId?.seoTitle);
  const [addMoreSeoTitile, setMoreSeoTitle] = useState("");
  const [SEODescription, setSEODescription] = useState(blogId?.seoDescription);
  const dispatch = useDispatch();
  // const { allblogs } = useSelector((state) => state.ProductCategory);
  // const initialContent = blogId?.content ? String(blogId.content) : "";
  // const blocksFromHTML = convertFromHTML(initialContent);
  // const contentState = ContentState.createFromBlockArray(
  //   blocksFromHTML.contentBlocks,
  //   blocksFromHTML.entityMap
  // );
  // const [details, setDetails] = useState(
  //   EditorState.createWithContent(contentState)
  // );

  // const onEditorStateChange = (editorState) => {
  //   setDetails(editorState);
  // };
  // console.log(blogId);
  const { title: titles, id } = useParams();
  console.log(title);

  const addMoreSkills = (e) => {
    if (e.key === "comma") {
      e.preventDefault();
      setMoreSeoTitle([...SEOTitle, SEOTitle]);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    let errorMessage = {};

    if (!title) {
      errorMessage.title = "Title is required";
    }

    if (!details) {
      errorMessage.details = "add your blog content";
    }

    if (!image) {
      errorMessage.image = "Blog banner is required";
    }

    if (Object.keys(errorMessage).length > 0) {
      setError(errorMessage);
      return;
    }

    const formdata = new FormData();
    formdata.append("title", title);
    // formdata.append("category", category);
    formdata.append(
      "content",details );
    formdata.append("file", image);
    formdata.append("seoTitle", SEOTitle);
    formdata.append("seoDescription", SEODescription);

    dispatch(updateBlogAction(id, formdata));
  };

  useEffect(() => {
    // if (blogId?.content) {
    //   const updatedContent = String(blogId.content); // Ensure it's a string
    //   const blocksFromHTML = convertFromHTML(updatedContent);
    //   const contentState = ContentState.createFromBlockArray(
    //     blocksFromHTML.contentBlocks,
    //     blocksFromHTML.entityMap
    //   );
      // setDetails(EditorState.createWithContent(contentState));
    // }
  }, [blogId]);

  useEffect(() => {
    if(isUpdated){
      alert("updated successfull")
    }
    dispatch(getBlogDetailsByID(titles));
    // dispatch(getAllBlogs());
  }, [dispatch,isUpdated,titles]);
  return (
    <div className="flex ">
      <SideBar />
      <div className="p-10">
        <div>
          <h1 className="uppercase font-semibold text-lg">Update Blogs</h1>
        </div>
        <form className="mt-10" onSubmit={onHandleSubmit}>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="title"
                id="floating_first_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <label
                for="floating_first_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Title
              </label>
              {Error.title && <p className="text-red ">{Error.title}</p>}
            </div>

            <div className="flex flex-col w-full">
              <label
                for="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold mr-1">
                      Click to upload your product banner
                    </span>
                    or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  className="hidden"
                  onChange={(e) => setImageUpload(e.target.files[0])}
                />
              </label>
              {Error.image && <p className="text-red ">{Error.image}</p>}
            </div>

            <div>
              <h1>Advance</h1>

              <div>
                <div className="relative z-0 w-full mb-5 group mt-2">
                  <input
                    type="text"
                    name="SEOTitle"
                    id="floating_first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={SEOTitle}
                    onChange={(e) => setSEOTitle(e.target.value)}
                    onKeyDown={addMoreSkills}
                  />
                  <label
                    for="floating_first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 "
                  >
                    SEO Post Title
                  </label>
                  <h1>{addMoreSeoTitile}</h1>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <textarea
                    type="text"
                    name="SEODescription"
                    id="floating_first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={SEODescription}
                    onChange={(e) => setSEODescription(e.target.value)}
                  />
                  <label
                    for="floating_first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    SEO Post Description
                  </label>
                </div>
              </div>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>

          <div
            className="w-full mt-5"
            style={{
              border: "1px solid black",
              padding: "10px",
              width: "100%",
              minHeight: "200px",
            }}
          >
            <MyEditor
              setDetails={setDetails}
            />
            {/* <textarea
              style={{ display: "none" }}
              disabled
              value={draftToHtml(convertToRaw(details.getCurrentContent()))}
            /> */}
          </div>

          <button
            type="submit"
            className="bg-red text-white mt-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-20 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </form>
      </div>

      {/* {allblogs &&
          allblogs.map((blog) => {
            return (
              <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-3">
                <a href="#">
                  <img class="rounded-t-lg" src={blog?.image?.url} alt="" />
                </a>
                <div class="p-5">
                  <a href="#">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {blog.title}
                    </h5>
                  </a>
                  <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          truncateText(blog.content, 25)
                        ),
                      }}
                    />
                  </p>
                  <a
                    href="#"
                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Read more
                    <svg
                      class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            );
          })} */}
    </div>
  );
};

export default UpdateBlog;
