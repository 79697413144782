import React from "react";


const Profile = () => {
  return (
    <div className="pl-3 md:pl-10 pr-3 md:pr-10 pb-10 pt-5 md:pt-10 bg-white text-black font-OpenSans">
      <div className="flex items-start justify-start space-x-2">
            <h1 className="font-bold text-2xl md:text-3xl">COMPANY PROFILE</h1>
      </div>
      <div>
        <p className="font-semibold text-base mt-7">
          Garuda Mart India Pvt. Ltd. is revolutionizing the home appliances
          industry in India with its unwavering dedication to quality,
          innovation, and customer satisfaction. Since its inception, Garuda
          Mart has rapidly carved out a niche for itself, becoming synonymous
          with trust and excellence.
        </p>
        <div className="flex space-x-2 mt-5">
          <h1 className="font-bold text-base w-3/12">Commitment to Quality</h1>
          <span className="font-semibold text-base">:</span>
          <h1 className="font-medium text-base flex flex-wrap w-11/12 ml-4">
            Every product from Garuda Mart undergoes rigorous testing to ensure
            it meets the highest standards of durability and performance. This
            commitment to quality has earned Garuda Mart a loyal customer base
            that relies on its products for everyday convenience and efficiency.
          </h1>
        </div>

        <div className="flex space-x-2 mt-5">
          <h1 className="font-bold text-base w-3/12">Innovative Solutions</h1>
          <span className="font-semibold text-base">:</span>
          <h1 className="font-medium text-base flex flex-wrap w-11/12 ml-4">
            At the heart of Garuda Mart’s success is its relentless pursuit of
            innovation. The company invests heavily in research and development,
            constantly seeking ways to integrate cutting-edge technology into
            its appliances. This innovation is evident in their range of
            products, from energy-efficient washing machines to advanced air
            purifiers that ensure a healthier living environment.
          </h1>
        </div>

        <div className="flex space-x-2 mt-5">
          <h1 className="font-bold text-base w-3/12">Customer Satisfaction</h1>
          <span className="font-semibold text-base">:</span>
          <h1 className="font-medium text-base flex flex-wrap w-11/12 ml-4">
            Understanding that customer satisfaction is paramount, Garuda Mart
            goes above and beyond to provide exceptional service. This includes
            easy-to-navigate online shopping platforms, prompt and reliable
            delivery services, and responsive customer support. Garuda Mart’s
            customer-centric approach ensures that every interaction, from
            purchase to after-sales service, is smooth and satisfactory.
          </h1>
        </div>

        <div className="flex space-x-2 mt-5">
          <h1 className="font-bold text-base w-3/12">Product Range</h1>
          <span className="font-semibold text-base">:</span>
          <h1 className="font-medium text-base flex flex-wrap w-11/12 ml-4">
            Garuda Mart boasts an extensive product portfolio designed to cater
            to diverse household needs. Their offerings include high-performance
            kitchen appliances, sophisticated home entertainment systems, and
            smart home devices that make daily tasks more manageable and
            enjoyable. Each product is crafted with attention to detail,
            combining functionality with aesthetic appeal.
          </h1>
        </div>

        <div className="flex space-x-2 mt-5">
          <h1 className="font-bold text-base w-3/12">
            Sustainability and Energy Efficiency
          </h1>
          <span className="font-semibold text-base">:</span>
          <h1 className="font-medium text-base flex flex-wrap w-11/12 ml-4">
            In a bid to promote sustainable living, Garuda Mart prioritizes the
            development of energy-efficient appliances. These products not only
            help reduce the carbon footprint but also offer significant cost
            savings to customers through lower energy bills.
          </h1>
        </div>

        <div className="flex space-x-2 mt-5">
          <h1 className="font-bold text-base w-3/12">
            Market Presence and Trust
          </h1>
          <span className="font-semibold text-base">:</span>
          <h1 className="font-medium text-base flex flex-wrap w-11/12 ml-4">
            Over the years, Garuda Mart has built a strong presence in the
            Indian market, earning the trust of millions of households. Their
            transparent business practices, ethical approach, and consistent
            delivery of high-quality products have solidified their reputation
            as a reliable and customer-focused company.
          </h1>
        </div>

        <div className="flex space-x-2 mt-10">
          <p className="font-semibold text-base">
            Garuda Mart India Pvt. Ltd. is not just a company; it is a
            pioneering force in the home appliances sector, committed to
            enhancing the quality of life for its customers through innovative,
            reliable, and sustainable solutions. As they continue to grow and
            evolve, Garuda Mart remains steadfast in its mission to lead the
            industry with integrity and excellence.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
