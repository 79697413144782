import React, { useEffect } from "react";
import SideBar from "../Dashboard/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../redux/action/authAction";

const AllUsers = () => {
  const { allusers ,loading} = useSelector((state) => state.allUsers);
  // console.log(allusers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);
  return (
    <div className="flex">
      <SideBar />
      <div className="w-full h-full p-5	">
        <div class="relative overflow-y-scroll h-[500px]  shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs bg-gray text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Id
                </th>
                <th scope="col" class="px-6 py-3">
                  Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Mobile
                </th>
                <th scope="col" class="px-6 py-3">
                  Role
                </th>
                <th scope="col" class="px-6 py-3">
                  <span class="">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="">
              {loading ? <>Loading...</> : allusers && allusers.map((user) => {
                return (
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 ">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {user._id}
                    </th>
                    <td class="px-6 py-4">{user.email}</td>
                    <td class="px-6 py-4">{user.mobile}</td>
                    <td class="px-6 py-4" style={{color: user.role === "admin" ? "green" : "black"}}>{user.role}</td>
                    <td class="px-6 py-4 ">
                      <h1
                        class="font-medium text-blue  hover:underline"
                      >
                        Edit
                      </h1>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
