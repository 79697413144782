import React from "react";
import CountUp from "react-countup";

const CounterCart = ({ number, title }) => {
  return (
    <div>
      <div className="w-full h-full   md:h-[80px] pt-2 md:pt-0 pb-2 md:pb-0  flex flex-wrap items-center justify-around">
        <div className="flex flex-col flex-wrap w-full items-center justify-center">
          <div className="flex items-center justify-center">
          <CountUp
            className="font-bold text-[18px] sm:text-3xl text-white"
            duration={3}
            end={number}
          />
          <h1 className="font-bold text-[10px] sm:text-3xl text-white ml-1">+</h1>
          </div>
          <p className="text-white  font-medium text-[8px] sm:text-xl ">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default CounterCart;
