import React from "react";
import gshoppiLogo from "../../image/gshoppi.jpg";
import garudayaanLogo from "../../image/graudayan.jpg";
import gplusLogo from "../../image/gplus brand.png";
import facebook from "../../image/Blod donation/facebook.jpg";
import twitter from "../../image/Blod donation/twitter.jpg";
import linkedin from "../../image/Blod donation/linkedin.jpg";
import youtube from "../../image/Blod donation/youtube.jpg";
import instagram from "../../image/Blod donation/instagram.jpg"; 

const Digital = () => {
  return (
    <div className="pr-2 md:pr-0 pl-2 md:pl-0 bg-white">
      <div className="mt-[20px] md:mt-[50px] pb-5">
        <div className="flex flex-col items-center justify-center w-full">
          <h1 className="font-bold text-black text-xl md:text-3xl text-center uppercase">
            Digital Platform
          </h1>
          <p className="font-medium  mt-2 text-center text-[10px] md:text-sm text-black">
            An innovative online space offering diverse tools, resources, and
            services to enhance digital experiences and connectivity.
          </p>
        </div>
        <div className="flex space-x-2 md:space-x-10 items-center justify-center mt-4 sm:mt-10 ">
          <a
            href="https://gshoppi.com/"
            target="_blank"
            rel="noreferrer"
            className="pl-5 pr-5  bg-white rounded"
          >
            <img className="w-[200px] " src={gshoppiLogo} alt="gshoppi" />
          </a>
          <a
            href="https://gplusindia.com/"
            target="_blank"
            rel="noreferrer"
            className="pl-5 pr-5  bg-white rounded "
          >
            <img className="w-[200px]  " src={gplusLogo} alt="gplus" />
          </a>
          <a
            href="https://garudayaan.com/"
            target="_blank"
            rel="noreferrer"
            className="pl-5 pr-5  bg-white rounded"
          >
            <img className="w-[200px] " src={garudayaanLogo} alt="garudayaan" />
          </a>
        </div>


        <div className="flex flex-row  items-center justify-center  space-x-7 sm:space-x-[80px] mt-5">
          <a
            href="https://www.facebook.com/GarudamartindiapvtLtd"
            className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
          >
            <img className="w-8 sm:w-[60px]" src={facebook} alt="facebook" />
          </a>
      
          <a
            href="https://www.instagram.com/garuda_mart_india/"
            className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
          >
            <img className="w-8 sm:w-[60px]" src={instagram} alt="twitter" />
          </a>
          <a
            href="https://www.youtube.com/@GarudaMartIndiaPvtLtd"
            className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
          >
            <img className="w-8 sm:w-[60px]" src={youtube} alt="facebook" />
          </a>
          <a
            href="https://twitter.com/GarudaMartIndia"
            className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
          >
            <img className="w-8 sm:w-[60px]" src={twitter} alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/garuda-mart-india-private-limited/"
            className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
          >
            <img className="w-8 sm:w-[60px]" src={linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Digital;
