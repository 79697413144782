import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetPassword } from "../../redux/action/authAction";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { id, token } = useParams();
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.userCarrerProfile);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
//   const navigate = useNavigate();

  const onHandleSubmit = (e) => {
    e.preventDefault();

    const myform = new FormData();

    myform.set("password", password);
    myform.set("confirm_password", confirmPassword);

    dispatch(resetPassword(id, token, myform));
    toast.success("password reset successfull");   
  };
  // console.log(success) 

  useEffect(() => {
    if (success) {
        alert(success);
        // toast.success("password reset successfull");
      }
  
      if (error) {
        alert(error);
      }
  }, [dispatch,success, error]);

  return (
    <div className="pt-20 pb-20 bg-white text-black font-Montserrat">
      <form className="max-w-sm mx-auto  shadow-lg p-10 rounded" onSubmit={onHandleSubmit}>
        <h1 className="mt-5 mb-5 font-medium text-base">Reset your Password</h1>
        <div className="mb-5">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-5">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Confirm Password
          </label>
          <input
            type="password"
            id="password"
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Confirm Password"
          />
        </div>
        <button
          type="submit"
          className="text-black bg-red text-white font-medium rounded-lg text-sm w-full pt-2 pb-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
