import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderImg = ({children}) => {
  var settings = {
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    scroll: true
  };
  return (
    <main>
        <Slider {...settings}>
            {children}
        </Slider>
    </main>
  )
}

export default SliderImg