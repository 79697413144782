import React, { useState, useEffect } from 'react';
import "froala-editor/css/froala_editor.pkgd.min.css"; // Editor CSS
import "froala-editor/css/froala_style.min.css"; // Additional styling
import "froala-editor/js/plugins.pkgd.min.js"; // Editor plugins
import FroalaEditorComponent from "react-froala-wysiwyg"; // Froala Editor component
import { useDispatch } from "react-redux";
import { uploadBlogImage } from "../../../../redux/action/BlogsAction";
import "./EditorStyles.css";

const Editor = ({ setDetails, details }) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState('');

  // Effect to update content when 'details' changes
  useEffect(() => {
    // Join the array into a string for the editor
    const initialContent = Array.isArray(details) ? details.join('\n') : details || '';
    setContent(initialContent); // Set content directly
  }, [details]);

  // Handle content changes in the editor
  const handleModelChange = (model) => {
    setContent(model); // Update the state when editor content changes
    setDetails(model); // Update the details if needed
  };

  const handleImageUpload = async (images) => {
    if (images.length) {
      const formData = new FormData();
      formData.append("file", images[0]); // Attach the first image

      try {
        const response = await dispatch(uploadBlogImage(formData)); // Call your Redux action
        return { link: response.link }; // Return the URL in the format Froala expects
      } catch (error) {
        console.error("Image upload failed:", error);
        return { error: "Image upload failed" }; // Return an error object if the upload fails
      }
    }
    return {}; // Default return value
  };

  return (
    <div className="w-full" id="froala-editor">
      <FroalaEditorComponent
        className="w-full"
        model={content} // Pass the combined content string
        onModelChange={handleModelChange} // Handle content changes
        config={{
          placeholderText: "Edit Your Content Here!",
          charCounterCount: true,
          imageUpload: true, // Enable image upload
          imageUploadURL: "/api/blogs/upload", // URL for image upload
          imageUploadMethod: "POST", // Use POST method for image upload
          events: {
            "froalaEditor.image.beforeUpload": function (e, editor, images) {
              if (images.length) {
                handleImageUpload(images).then((response) => {
                  if (response.link) {
                    editor.image.insert(response.link, true);
                    editor.events.focus(); // Ensure focus after image insertion
                  } else {
                    console.error(response.error);
                  }
                });
                return false; // Prevent Froala's default upload behavior
              }
            },
          },
        }}
      />
    </div>
  );
};

export default Editor;
