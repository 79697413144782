import React from "react";
import { Link } from "react-router-dom";
import { premiumData } from "./PremiumObj";

const PremiumProduct = () => {
  return (
    <div className="w-full h-full pb-[30px] md:mb-[100px] w-full pl-3 md:pl-8 pr-3 md:pr-8 bg-white">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <h1 className="font-semibold text-xl md:text-3xl text-center uppercase mt-5">
          G-plus Premium Appliances
        </h1>
        <p className="font-medium pt-2 text-center text-[10px] md:text-sm">
          Discover G-plus: where cutting-edge technology meets elegant design,
          delivering unmatched performance and energy savings for your home.
        </p>
        <div className="flex flex-row  items-center jsutify-center  md:mt-10 space-x-1 md:space-x-6 pt-8">
          {premiumData &&
            premiumData.map((item) => {
              return (
                <div className="w-[85px] md:w-[280px] md:h-[300px] flex items-center  justify-center flex-col ">
                  <img className="w-full h-full" src={item.imageUrl} alt="" />
                  <Link
                    className="pt-2"
                    to={item.url}
                  >
                    <button className="w-[70px] md:w-48 pt-1 md:pt-2 pb-1 md:pb-2 bg-[#9F2725] font-semibold text-[14px]  md:text-sm text-white rounded-full ">
                     View
                    </button>
                  </Link>
                </div>
              );
            })}

          {/* <div className="w-[280px] h-[250px] flex items-center justify-center flex-col ">
            <img src={ref} alt="" />
            <Link
              className="pt-2"
              to="https://gshoppi.com/product/GPLUS-528-L-FRENCH-DOOR-BOTTOM-FREZZER-Refrigerator-(Silver-GM-528W)/65eae981145ae609d59a8577"
            >
              <button className="pl-10 pr-10 pt-2 pb-2 bg-[#9F2725] text-white rounded ">
                Buy Now
              </button>
            </Link>
          </div>
          <div className="w-[280px] h-[250px] flex items-center justify-center flex-col ">
            <img src={dishwasher} alt="" />
            <Link
              className="pt-2"
              to="https://gshoppi.com/product/GPLUS-DISH-WASHER-16-PLACE-SETTINGS-black-(GM-DW-ULTRAX16)/658bbdfb0d16b48350c46ce4"
            >
              <button className="pl-10 pr-10 pt-2 pb-2 bg-[#9F2725] text-white rounded ">
                Buy Now
              </button>
            </Link>
          </div>
          <div className="w-[280px] h-[250px] flex items-center justify-center flex-col ">
            <img src={tv85} alt="" />
            <Link
              className="pt-2"
              to="https://gshoppi.com/product/GPLUS-QLED-214-Cm-(85-Inches)-Ultra-HD-4K/65d9baf529cf4480e2ffdabc"
            >
              <button className="pl-10 pr-10 pt-2 pb-2 bg-[#9F2725] text-white rounded ">
                Buy Now
              </button>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PremiumProduct;
