import {
  CREATE_SOCIAL_REQUIEST,
  CREATE_SOCIAL_SUCCESS,
  CREATE_SOCIAL_FAIL,
  GET_ALL_SOCIAL_REQUIEST,
  GET_ALL_SOCIAL_SUCCESS,
  GET_ALL_SOCIAL_FAIL,
  GET_SOCIAL_BY_ID_REQUEST,
  GET_SOCIAL_BY_ID_SUCCESS,
  UPDATE_SOCIAL_BY_REQUIEST,
  UPDATE_SOCIAL_BY_SUCCESS,
  GET_SOCIAL_BY_ID_FAIL,
  UPDATE_SOCIAL_BY_FAIL,
  DELETE_BY_SOCIAL_BY_ID_REQUIEST,
  DELETE_BY_SOCIAL_BY_ID_SUCCESS,
  DELETE_BY_SOCIAL_BY_ID_FAIL,
} from "../constants/socialConstant";

export const createSocialReducers = (state = { social: [] }, action) => {
  switch (action.type) {
    case CREATE_SOCIAL_REQUIEST:
    case GET_ALL_SOCIAL_REQUIEST:
    case GET_SOCIAL_BY_ID_REQUEST:
    case UPDATE_SOCIAL_BY_REQUIEST:
    case DELETE_BY_SOCIAL_BY_ID_REQUIEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_SOCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_SOCIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        allSocial: action.payload,
      };

    case GET_SOCIAL_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        socialId: action.payload,
      };

    case UPDATE_SOCIAL_BY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };

    case DELETE_BY_SOCIAL_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case CREATE_SOCIAL_FAIL:
    case GET_ALL_SOCIAL_FAIL:
    case GET_SOCIAL_BY_ID_FAIL:
    case UPDATE_SOCIAL_BY_FAIL:
    case DELETE_BY_SOCIAL_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
