import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyForJob } from "../../redux/action/carresAction";
import { Link, useParams } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import "./CarrerForm.css";
import { getCarrerAppByUserId } from "../../redux/action/authAction";
import { BsArrowUpRight } from "react-icons/bs";
import { BsPlus } from "react-icons/bs";
import { toast } from "react-toastify";

const CarrerForm = () => {
  const [toggle, setToggle] = useState(false);
  const [error, setError] = useState({});
  const [resume, setResume] = useState("");
  // const [CheckState,setCheckState] = useState(false)
  const dispatch = useDispatch();
  const { success, error: carrerError } = useSelector((state) => state.applyed);
  const { userCarrerProfile } = useSelector((state) => state.userCarrerProfile);
  // console.log(userCarrerProfile);
  const { id } = useParams();

  // console.log(id);
  const [skill, setSkills] = useState("");
  const [moreSkill, setMoreSkill] = useState([]);
  // console.log(skill);

  // check user already applied for this job if no then form will display and if yes then will display user already upplied for this job
  const exist =
    userCarrerProfile &&
    userCarrerProfile.some((job) => job.jobrole._id === id);

  const addMoreSkills = (e) => {
    if (e.key === "Enter" && skill) {
      e.preventDefault();
      setMoreSkill([...moreSkill, skill]);
      setSkills(" ");
    }
    console.log(e);
  };

  const onHandleRemove = (val) => {
    const removeSkill = moreSkill.filter((item) => item !== val);
    setMoreSkill(removeSkill);
  };

  const [dataItem, setDataItem] = useState({
    firstName: "",
    LastName: "",
    email: "",
    Mobile: "",
    CurrentCtc: "",
    ExpectedCTC: "",
    Notice: "",
  });

  // add more social profile url
  const [SocialUrl, setMoreSocialUrl] = useState([
    {
      moreSocialUrl: "",
    },
  ]);

  const onHandleMoreSocialUrl = (e, i) => {
    const { name, value } = e.target;
    const chenageUrl = [...SocialUrl];
    chenageUrl[i][name] = value;
    setMoreSocialUrl(chenageUrl);
  };

  const addMoreSocialUrl = () => {
    setMoreSocialUrl([...SocialUrl, { moreSocialUrl: "" }]);
  };

  // employer data
  const [data, setData] = useState([
    {
      designation: "",
      companyName: "",
      startDate: "",
      endDate: "",
      summary: "",
      location: "",
      check: false,
    },
  ]);

  const onHandleClick = () => {
    setData([
      ...data,
      {
        designation: "",
        companyName: "",
        startDate: "",
        endDate: "",
        summary: "",
        check: false,
      },
    ]);
  };

  const deleteHandle = (index) => {
    const deleteItem = [...data];
    deleteItem.splice(index, 1);
    setData(deleteItem);
  };

  const onHandleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    const onchangeValue = [...data];
    onchangeValue[index][name] = type === "checkbox" ? checked : value;
    setData(onchangeValue);
  };

  const onHandleChangeFrom = (e) => {
    const { name, value } = e.target;
    setDataItem((prevDataItem) => ({
      ...prevDataItem,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let errorMessage = {};
    if (!dataItem.firstName) {
      errorMessage.firstName = "First name is required";
    }
    if (!dataItem.LastName) {
      errorMessage.LastName = "Last name is required";
    }
    if (!dataItem.email) {
      errorMessage.email = "Email is required";
    }
    if (!dataItem.Mobile) {
      errorMessage.Mobile = "number is required";
    }

    if (!resume) {
      errorMessage.resume = "resume is required";
    }

    // console.log("dataItem", dataItem);
    // console.log("data", data);

    if (Object.keys(errorMessage).length > 0) {
      setError(errorMessage);
      return;
    }

    const myForm = new FormData();
    myForm.append("firstName", dataItem.firstName);
    myForm.append("LastName", dataItem.LastName);
    myForm.append("email", dataItem.email);
    myForm.append("Mobile", dataItem.Mobile);
    myForm.append("CurrentCTC", dataItem.CurrentCtc);
    myForm.append("ExpectedCTC", dataItem.ExpectedCTC);
    myForm.append("Notice", dataItem.Notice);
    myForm.append("jobrole", id);
    myForm.append("file", resume);

    for (let i = 0; i < SocialUrl.length; i++) {
      myForm.append("socialProfile", SocialUrl[i].moreSocialUrl);
    }

    for (let i = 0; i < data.length; i++) {
      myForm.append("Employer", JSON.stringify(data[i]));
    }

    myForm.append("skills", moreSkill);
    // console.log("data",data)

    dispatch(applyForJob(myForm));
  };

  useEffect(() => {
    if (success) {
      toast.success("appled successfull");
    }
    if (carrerError) {
      alert(carrerError);
    }
    dispatch(getCarrerAppByUserId());
  }, [dispatch, success, error, carrerError]);

  return (
    <div className="flex flex-col bg-white text-black w-full items-center pb-10  rounded font-Montserrat">
      {exist ? (
        userCarrerProfile.map((job) => {
          if (job.jobrole._id === id) {
            return (
              <div className="bg-white  h-[200px] flex flex-col items-center justify-center">
                <h1 className="font-semibold text-lg">
                  candidate already present in job
                </h1>
                <Link
                  to="/carrers"
                  className="font-medium text-lg mt-3 flex items-center justify-center"
                >
                  Check more opportunity
                  <BsArrowUpRight color="gray" className="ml-2" />
                </Link>
              </div>
            );
          }
          return null;
        })
      ) : (
        <div className="w-full sm:w-8/12">
          <h1 className="font-semibold text-lg pt-5">
            Submit Your Application
          </h1>
          <div className="w-full flex items-center justify-center mt-10">
            <form class="w-full p-3" onSubmit={onSubmit}>
              <div className="sm:flex w-full sm:space-x-2">
                <div class="relative z-0 sm:w-1/2 mb-5 group">
                  <input
                    type="firstname"
                    name="firstName"
                    id="floating_email"
                    value={dataItem.firstName}
                    onChange={(e) => onHandleChangeFrom(e)}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    First Name
                    <span className="text-red ml-2">*</span>
                  </label>
                  {error.firstName && (
                    <p className="text-red">{error.firstName}</p>
                  )}
                </div>
                <div class="relative z-0 sm:w-1/2 mb-5 group">
                  <input
                    type="lastname"
                    name="LastName"
                    id="floating_email"
                    value={dataItem.LastName}
                    onChange={(e) => onHandleChangeFrom(e)}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Last Name
                    <span className="text-red ml-2">*</span>
                  </label>
                  {error.LastName && (
                    <p className="text-red">{error.LastName}</p>
                  )}
                </div>{" "}
              </div>

              <div className="flex w-full space-x-2">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="email"
                    name="email"
                    id="floating_email"
                    value={dataItem.email}
                    onChange={(e) => onHandleChangeFrom(e)}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Email
                    <span className="text-red ml-2">*</span>
                  </label>
                  {error.email && <p className="text-red">{error.email}</p>}
                </div>
              </div>

              <div className="flex w-full space-x-2">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="number"
                    name="Mobile"
                    id="floating_email"
                    value={dataItem.Mobile}
                    onChange={(e) => onHandleChangeFrom(e)}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Mobile
                    <span className="text-red ml-2">*</span>
                  </label>
                  {error.Mobile && <p className="text-red">{error.Mobile}</p>}
                </div>
              </div>

              {/* current ctc */}
              <div className="flex w-full space-x-2">
                <div class="relative z-0 w-1/2 mb-5 group">
                  <input
                    type="text"
                    name="CurrentCtc"
                    id="floating_email"
                    value={dataItem.CurrentCtc}
                    onChange={(e) => onHandleChangeFrom(e)}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Current CTC
                  </label>
                </div>{" "}
                <div class="relative z-0 w-1/2 mb-5 group">
                  <input
                    type="text"
                    name="ExpectedCTC"
                    id="floating_email"
                    value={dataItem.ExpectedCTC}
                    onChange={(e) => onHandleChangeFrom(e)}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Expected CTC
                  </label>
                </div>{" "}
              </div>

              <div className="flex w-full space-x-2">
                <div class="relative z-0 w-1/2 mb-5 group">
                  <input
                    type="text"
                    name="Notice"
                    id="floating_email"
                    value={dataItem.Notice}
                    onChange={(e) => onHandleChangeFrom(e)}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Notice Period (days)
                  </label>
                </div>
              </div>
              <div>
                <div className="mt-5">
                  <h1>Social Network and Web Links (Optional)</h1>
                  <p>
                    Provide us with links to see some of your work (Linkedin)
                  </p>
                  <div className="flex flex-col w-full mt-5">
                    {SocialUrl.map((social, i) => {
                      return (
                        <div className="w-full flex items-center justify-center space-x-3">
                          <div class="relative z-0 w-full mb-5 group">
                            <input
                              type="url"
                              name="moreSocialUrl"
                              id="floating_email"
                              value={social.moreSocialUrl}
                              onChange={(e) => onHandleMoreSocialUrl(e, i)}
                              class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                            />
                            <label
                              for="floating_email"
                              class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                              Social Network and Web Links
                            </label>
                          </div>
                          <button
                            className="w-10 h-[30px] border rounded flex items-center justify-center"
                            onClick={addMoreSocialUrl}
                          >
                            <BsPlus size={25} />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="border p-5">
                  <h1 className="flex items-center">
                    Employer
                    {/* <span className="text-red ml-2">*</span> */}
                  </h1>

                  <div>
                    {toggle ? (
                      <div>
                        {data &&
                          data.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="flex w-full mt-5">
                                  <div class="relative z-0 w-full mb-5 group">
                                    <input
                                      type="text"
                                      name="designation"
                                      value={item.designation}
                                      id="floating_email"
                                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                      placeholder=""
                                      onChange={(e) => onHandleChange(e, index)}
                                    />
                                    <label
                                      for="floating_email"
                                      class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                      Designation
                                    </label>
                                  </div>
                                </div>
                                <div className="flex w-full mt-5">
                                  <div class="relative z-0 w-full mb-5 group">
                                    <input
                                      type="text"
                                      name="companyName"
                                      value={item.companyName}
                                      id="floating_email"
                                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                      placeholder=""
                                      onChange={(e) => onHandleChange(e, index)}
                                    />
                                    <label
                                      for="floating_email"
                                      class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                      Company Name
                                    </label>
                                  </div>
                                </div>
                                <div className="flex w-full mt-5">
                                  <div class="relative z-0 w-full mb-5 group">
                                    <input
                                      type="text"
                                      name="location"
                                      value={item.location}
                                      id="floating_email"
                                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                      placeholder=""
                                      onChange={(e) => onHandleChange(e, index)}
                                    />
                                    <label
                                      for="floating_email"
                                      class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                      Location
                                    </label>
                                  </div>
                                </div>
                                <div className="flex w-full mt-5 space-x-3">
                                  <div class="relative z-0 w-1/2 mb-5 group">
                                    <input
                                      type="date"
                                      name="startDate"
                                      value={item.startDate}
                                      id="floating_email"
                                      class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                      placeholder=""
                                      onChange={(e) => onHandleChange(e, index)}
                                    />
                                    <label
                                      for="floating_email"
                                      class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                      start date
                                    </label>
                                  </div>
                                  {item.check === true ? (
                                    ""
                                  ) : (
                                    <div class="relative z-0 w-1/2 mb-5 group">
                                      <input
                                        type="date"
                                        name="endDate"
                                        value={item.endDate}
                                        id="floating_email"
                                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        placeholder=""
                                        onChange={(e) =>
                                          onHandleChange(e, index)
                                        }
                                      />
                                      <label
                                        for="floating_email"
                                        class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                      >
                                        End Date
                                      </label>
                                    </div>
                                  )}
                                </div>
                                <div class="flex items-center mb-4">
                                  <input
                                    id="default-checkbox"
                                    name="check"
                                    type="checkbox"
                                    checked={item.check}
                                    onChange={(e) => onHandleChange(e, index)}
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label
                                    for="default-checkbox"
                                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    currently working
                                  </label>
                                </div>

                                <div class="relative z-0 w-full mb-5 group mt-5">
                                  <label
                                    for="message"
                                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                  >
                                    Your message
                                  </label>
                                  <textarea
                                    id="message"
                                    rows="4"
                                    name="summary"
                                    value={item.summary}
                                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=""
                                    onChange={(e) => onHandleChange(e, index)}
                                  ></textarea>
                                </div>
                                <div className="mt-5 space-x-3">
                                  <button
                                    className="border bg-gray rounded pl-7 pr-7 pt-2 pb-2"
                                    onClick={() => deleteHandle(index)}
                                  >
                                    Remove this Employer
                                  </button>

                                  <button
                                    className="border bg-gray rounded pl-7 pr-7 pt-2 pb-2"
                                    onClick={onHandleClick}
                                  >
                                    + Add Employer
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div className="mt-5">
                        <button
                          className="border bg-gray rounded pl-7 pr-7 pt-2 pb-2"
                          onClick={() => setToggle(!toggle)}
                        >
                          + Add Employer
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-full space-x-2 mt-5">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="skill"
                    id="floating_email"
                    value={skill}
                    onChange={(e) => setSkills(e.target.value)}
                    onKeyDown={addMoreSkills}
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_email"
                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Skills
                    <span className="text-red ml-2">*</span>
                  </label>

                  <div className="flex flex-wrap">
                    {moreSkill.map((item, index) => {
                      return (
                        <ul className="flex p-1">
                          <li className="pl-3 pr-2 border  rounded-full flex items-center uppercase">
                            {item}
                            <IoIosClose
                              size={25}
                              color="red"
                              className="cursor-pointer ml-1"
                              onClick={() => onHandleRemove(item)}
                            />
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <h1>
                  Resume <span className="text-red ml-2">*</span>
                </h1>
                <input
                  type="file"
                  name="file"
                  className="border inputFile w-full sm:w-auto"
                  placeholder="upload your resume"
                  onChange={(e) => setResume(e.target.files[0])}
                  accept="application/pdf,.docx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                {error.resume && <p className="text-red">{error.resume}</p>}
              </div>

              <button
                className="rounded mt-10 pl-5 sm:pl-10 pr-5 sm:pr-10 pt-1 sm:pt-3 pb-1 sm:pb-3 bg-red text-white"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarrerForm;
