import React from "react";
import conterBg from "../../image/Header.jpg";
import CounterCart from "./CounterCart";
import { NavLink } from "react-router-dom";


const CounterDetails = () => {
  return (
    <div
      className="w-full mb-10 flex items-center justify-around h-full"
      style={{
        backgroundImage: `url(${conterBg})`,
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <CounterCart number={7} title={"Years of Journey"} />
      <CounterCart number={100000} title={"Satisfied Customer"} />
      <CounterCart number={100} title={"Awards & Rewards"} />
      <CounterCart number={1500} title={"Channel Partners"} />
      <NavLink to="https://gplusindia.com/contact-us"> 
      <CounterCart number={30} title={"G-Digital Outlets"} /> 
      </NavLink>
      <CounterCart number={20} title={"Gplus Products"} /> 
    </div>
  );
};

export default CounterDetails;
