import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/action/authAction";
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const {user,error} = useSelector((state)=>state.userCarrerProfile)
    const [email,setEmail] = useState("")
    const OnHandleSubmit = (e) =>{
        e.preventDefault()
        const data = {
            email: email,
            subject: "forgot password"
        }
        dispatch(forgotPassword(data))
    }

    useEffect(()=>{
        if(user){
            alert("email send successfull")
        }

        if(error){
            toast.error(error)
        }
    },[dispatch, user, error])


  return (
    <div className="w-full pt-10 pb-10 flex items-center justify-center bg-white text-black">
    <div className="w-full md:w-2/6 shadow rounded flex flex-col items-center justify-center mt-10 mb-10">
        <h1 className="font-semibold text-lg pt-5 capitalize">forgot your password</h1>
      <form className="w-full  flex flex-col  justify-center pl-5 pr-5 pt-10 pb-10" onSubmit={OnHandleSubmit}>
        <div className="mb-5 w-full">
          <label
            for="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Your email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={(e)=>setEmail(e.target.value)}
            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter your email"
          />
        </div>

        <button
          type="submit"
          className="text-black w-[200px] bg-gray hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
        </div>
    </div>
  );
};

export default ForgotPassword;
