import React, { useEffect } from "react";
import SideBar from "../Dashboard/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { deleteBySocialId, getAllSocialAction } from "../../../redux/action/socialAction";
import { Link } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";



const AllSocialProgram = () => {
  const dispatch = useDispatch();
  const { allSocial, loading ,isDeleted} = useSelector((state) => state.createSocial);
 
  console.log(allSocial);
  const onHandleDelete = (id) =>{
    dispatch(deleteBySocialId(id))
  }

  useEffect(() => {
    if(isDeleted){
      toast.success("social item deleted successfull")
    }
    dispatch(getAllSocialAction());
  }, [dispatch,isDeleted]);
  return (
    <div className="flex font-Montserrat">
      <SideBar />
      <div className="p-5 pt-10 w-full">
        <div>
        <Link
            to="/dashboard/social-contribution/create-event"
            className="bg-gray pl-20 pr-20 pt-5 pb-5 shadow rounded font-semibold text-sm"
          >
            Add More Event
          </Link>
        </div>
        <div className="w-full mt-10">
          <div class="relative overflow-x-auto">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Social Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Event
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Category
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Years
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? <h1>Loading...</h1> : allSocial && allSocial.length ? allSocial &&
                  allSocial.map((social) => {
                    return (
                      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {social.name}
                        </th>
                        <td class="px-6 py-4">{social.heading}</td>
                        <td class="px-6 py-4 uppercase">
                          {social?.category.name}
                        </td>
                        <td class="px-6 py-4">{social.years}</td>
                        <td class="px-6 py-4">
                          <Link to={`/dashboard/social-contribution/update-event/${social._id}`}><CiEdit size={25} color="green"/></Link>
                        </td>
                        <td class="px-6 py-4">
                          <Link to="" onClick={()=>onHandleDelete(social._id)}><MdDelete size={25} color="red"/></Link>
                        </td>
                      </tr>
                    );
                  }) : " upload your event"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSocialProgram;
