import React from "react";

const Expedition = () => {
  return (
    <div className="pl-3 sm:pl-10 pr-3 sm:pr-10 pt-10 font-Montserrat space-y-3 pb-10 bg-white text-black">
      <h1 className="font-bold text-sm sm:text-base">About Garuda Mart India Pvt. Ltd.</h1>
      <p className="font-medium text-xs sm:text-base mt-5">
        Founded in 2017 by visionary entrepreneur Revuru Venkataramana, Garuda
        Mart India Pvt. Ltd. has rapidly evolved into one of the leading players
        in the Indian consumer electronics and home appliances market.
        Headquartered in Bangalore, the company has made significant strides by
        catering to the growing demand for high-quality, reliable, and
        affordable products across southern India, with a particular focus on
        tier-2 cities.
      </p>
      <p className="font-medium text-xs sm:text-base">
        Starting as a small retail outlet, Garuda Mart quickly recognized the
        potential in underserved regions, strategically positioning itself to
        offer a comprehensive range of products. From home appliances such as
        washing machines, refrigerators, and LED TVs to everyday essentials like
        dishwashers, coolers, fans, and more, Garuda Mart has successfully
        expanded its portfolio under the VYOM and GPlus brands. The company's
        commitment to excellence is underscored by its network of over 1,200
        dealers and more than 200,000 satisfied customers.
      </p>
      <p className="font-medium text-xs sm:text-base">Pioneering Sustainable Solutions and Innovation</p>
      <p className="font-medium text-xs sm:text-base">
        Garuda Mart's success is built on a foundation of continuous innovation
        and a deep understanding of market needs. By prioritizing technological
        advancements and ensuring stringent quality control, the company
        maintains a strong competitive edge. With a complaint ratio as low as
        0.25%, Garuda Mart consistently delivers products that are not only
        technologically advanced but also environmentally sustainable, setting
        new benchmarks for quality and performance in the industry.
      </p>
      <p className="font-medium text-xs sm:text-base">
        Under Venkataramana’s dynamic leadership, the company has expanded its
        operations to encompass multiple sectors, including manufacturing,
        logistics, food and beverages, EV mobility, and exports. One of its
        notable ventures is becoming the South Indian distributor for Joy
        e-bikes, marking Garuda Mart’s foray into the rapidly growing electric
        vehicle market. This strategic move aligns with the company’s vision of
        contributing to eco-friendly and sustainable solutions for the future.
      </p>
      <p className="font-medium text-xs sm:text-base">Ambitious Growth and Future Prospects</p>
      <p className="font-medium text-xs sm:text-base">
        Garuda Mart’s journey has been marked by an unwavering focus on customer
        satisfaction, which has driven its rapid expansion and success in
        various industries. Looking ahead, the company has ambitious plans to
        expand its G-DIGITAL retail chain to over 500 stores across India, with
        a target turnover of ₹1,500 crore in the next few years. Garuda Mart
        also envisions further strengthening its logistics network and enhancing
        its manufacturing capabilities to meet the rising demands of its
        customers.
      </p>
      <p className="font-medium text-xs sm:text-base">
        The company’s remarkable achievements have not gone unnoticed. Garuda
        Mart has been honored with multiple national awards for business
        excellence and outstanding consumer products, cementing its position as
        a trusted brand in the Indian market.
      </p>
      <p className="font-medium text-xs sm:text-base">
        At Garuda Mart India Pvt. Ltd., we are committed to setting new
        standards of excellence through innovation, customer satisfaction, and
        sustainable business practices. As we continue to grow, we remain
        dedicated to delivering top-quality products and services that enhance
        the lives of our customers and contribute to a greener, more prosperous
        future.
      </p>
    </div>
  );
};

export default Expedition;
