import React, { useEffect, useRef, useState } from "react";
import {
  PositionDescription,
  getApplicantAction,
} from "../../redux/action/carresAction";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CarrerForm from "./CarrerForm";
// import { toast } from "react-toastify";

const JobDescription = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isFormVisible, setFormVisible] = useState(false);
  const formRef = useRef(null);
  const { positionId, allApplicant, loading } = useSelector(
    (state) => state.jobPosition
  );

  // const addSpacingToHtmlContent = (html) => {
  //   // Example: Add margin to paragraphs
  //   return html
  //     ?.replace(/<li>/g, '<li style="margin: 10px 0;">')
  //     .replace(/<p>/g, '<p style="margin: 15px 0;">')
  //     .replace(/<h([1-6])>/g, '<h$1 style="margin: 20px 0;">')
  //     .replace(/<\/li>/g, "</li>")
  //     .replace(/<\/p>/g, "</p>")
  //     .replace(/<\/h([1-6])>/g, "</h$1>");
  // };

  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  // location.search ? location.search.split("=")[1] : "/profile"
  const handleApplyButtonClick = (e) => {
    if (!user || !user.token === undefined) {
      // Redirect to login if not logged in
      navigate("/login");
    } else {
      // Scroll to the form if logged in
      setFormVisible(true);
      setTimeout(() => {
        if (formRef.current) {
          formRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  };

  useEffect(() => {
    dispatch(PositionDescription(id));
    dispatch(getApplicantAction(id));
  }, [dispatch, id]);

  return (
    <>
      <div className="font-Montserrat w-full bg-white text-black">
        <div
          className="w-auto sm:w-full sm:h-[400px] flex items-center justify-start"
          style={{
            backgroundImage: `url(${positionId?.image.url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",

          }}
        >
          {/* <img className="w-full h-full" src={positionId?.image.url} alt="" /> */}
          <div className="p-5 w-full">
            <div className="space-y-1 sm:space-y-3">
              <div className="sm:w-8/12">
                <h1 className="font-bold text-sm sm:text-3xl  text-white capitalize">
                  {positionId?.name}
                </h1>
              </div>
              <div className="flex flex-col space-y-2">
                <h1 className="text-white font-bold text-xs sm:text-base">
                  {positionId?.location}
                </h1>
                <h1 className="text-white font-bold text-xs sm:text-base">
                  Applicants : {allApplicant}
                </h1>
              </div>
            </div>
            <button
              onClick={handleApplyButtonClick}
              className="font-semibold mt-3 text-sm sm:text-lg text-white bg-[#9F2725] pl-10 pr-10 pt-2 pb-2 rounded"
            >
              Apply
            </button>
          </div>
        </div>
        {loading ? (
          <h1>Loading...</h1>
        ) : (
          <div>
            <div className="p-5 sm:p-10 w-full flex">
              <p className="font-medium text-sm sm:text-base sm:pr-5">
                {positionId?.details.map((con) => {
                  // console.log(con);
                  return (
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: con,
                      }}
                    />
                  );
                })}
              </p>
            </div>
            {isFormVisible && (
              <div ref={formRef}>
                <CarrerForm />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default JobDescription;
