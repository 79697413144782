import app from "../../image/app.jpg"
import expedition from "../../image/exp.jpg"
import Journals from "../../image/journa,s.jpg"
import Finance from "../../image/fian.jpg"

export const DataObj = [
    {
        id: 0,
        image: app,
        route: "/appreciation",
        heading: "Appreciation",
        details: "Discover heartfelt acknowledgments and praise from our valued customers, partners, and community member."
    },
    {
        id: 1,
        image: expedition,
        route: "/about-us",
        heading: "Expedition",
        details: "Experience our journey of continuous growth and expansion, navigating new horizons in the world of expeditions and adventure."
    },
    {
        id: 2,
        image: Journals,
        route: "/journals",
        heading: "Journals",
        details: " Explore insightful journals chronicling the vision and milestones of our Managing Director and our company's evolution."
    },
    {
        id: 3,
        image: Finance,
        route: "/finance",
        heading: "Finance",
        details: "Explore our finance company's growth trajectory, investor relations, investment opportunities, and audit insights for transparency and trust."
    }
]