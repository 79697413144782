import React, { useEffect, useState } from "react";
import SideBar from "../../Dashboard/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { getSocialCategory } from "../../../../redux/action/socialCategoryAction";
import { createSocialAction } from "../../../../redux/action/socialAction";
import { toast } from "react-toastify";

const AddMoreEvent = () => {
  const [image, setImage] = useState("");
  const [name, setName] = useState();
  const [heading, setHeading] = useState();
  const [years, setYears] = useState();
  const [SocialCategory, setSocialCategory] = useState();
  const [Error, setError] = useState("");
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.socialCategory);
  const { success} = useSelector((state) => state.createSocial);
  console.log(category);
  console.log(image)

  const onSubmitHandler = (e) => {
    e.preventDefault();

    let errorMessage = {};

    if (!name) {
      errorMessage.name = "name is required";
    }

    if (!years) {
      errorMessage.years = "Years is required";
    }

    if (!SocialCategory) {
      errorMessage.SocialCategory = "Social Category is required";
    }
    if (!heading) {
      errorMessage.heading = "heading is required";
    }

    if (!image) {
      errorMessage.image = "image is required";
    }

    // console.log("dataItem", dataItem);
    // console.log("data", data);

    if (Object.keys(errorMessage).length > 0) {
      setError(errorMessage);
      return;
    }

    const formdata = new FormData()
    formdata.append("name",name)
    formdata.append("years",years)
    formdata.append("heading",heading)
    formdata.append("category",SocialCategory)
    for(let i = 0; i<image.length; i++){
        formdata.append("files",image[i])
    }
   

    dispatch(createSocialAction(formdata))
  };

  useEffect(() => {
    if(success){
      toast.success("social upload successfull")
    }
    dispatch(getSocialCategory());
  }, [dispatch,success]);
  return (
    <div className="flex font-Montserrat">
      <SideBar />
      <div className="p-5 pt-10 w-full">
        <div>
          <h1 className="font-semibold text-lg">Add Social Event</h1>
        </div>
        <form className="mt-5 " onSubmit={onSubmitHandler}>
          <div className="flex w-full">
            <div className="relative z-0 w-full mb-5 group mr-2">
              <input
                type="text"
                name="name"
                id="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setName(e.target.value)}
              />
              <label
                for="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Name
              </label>
              {Error.name && <p className="text-red ">{Error.name}</p>}
            </div>
            <div className="relative z-0 w-full mb-5 group ml-2">
              <input
                type="date"
                name="years"
                id="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setYears(e.target.value)}
              />
              <label
                for="floating_email"
                className="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Years of event
              </label>
              {Error.years && <p className="text-red ">{Error.years}</p>}
            </div>
          </div>
          <div className="flex w-full">
            <div className="relative z-0 w-full mb-5 group mr-2">
              <input
                type="text"
                name="heading"
                id="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setHeading(e.target.value)}
              />
              <label
                for="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Heading
              </label>
              {Error.heading && <p className="text-red ">{Error.heading}</p>}
            </div>
            <div className="w-full">
              <select
                name=""
                id=""
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                onChange={(e) => setSocialCategory(e.target.value)}
              >
                <option value="Select Event category">
                  Select Event Category
                </option>
                {category &&
                  category.map((cate) => {
                    return (
                      <option value={cate._id} className="capitalize">
                        {cate.name}
                      </option>
                    );
                  })}
              </select>
              {Error.SocialCategory && (
                <p className="text-red ">{Error.SocialCategory}</p>
              )}
            </div>
          </div>

          <div class="flex flex-col   w-full">
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                {image.length} files
                  <span class="font-semibold ml-4">   Click to upload</span>
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                multiple
                class="hidden"
                onChange={(e) =>setImage(e.target.files)}
              />
            </label>
            {Error.image && <p className="text-red capitalize">{Error.image}</p>}
          </div>

          <button
            type="submit"
            className="text-white font-semibold text-lg bg-red  pl-20 pr-20 pt-3 pb-3 mt-5 rounded "
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddMoreEvent;
