import React from 'react'
// import graph from "../../image/graph.jpeg";
// import contactImg from "../../image/contact.webp";
import mission from "../../image/Garudayaan/fture.jpg";
import graphs from "../../image/Garudayaan/past.jpg"
import { Link } from 'react-router-dom';

const Graph = () => {
  return (
    <div className='bg-white text-black'>
         <div className="flex flex-col items-center justify-center mb-[60px] md:mb-[120px]">
        {/* <h1 className="font-bold text-xl">Our vision</h1> */}
        <div className="flex flex-col md:flex-row items-center justify-center  md:pt-10 w-full md:w-11/12 space-y-10 md:space-y-0">
          <div className="w-5/6 flex items-center justify-center relative mb-20 md:mb-0">
            <img
              className="md:w-9/12 hover:scale-105 transition duration-700 ease-in-out hover:text-red rounded"
              src={graphs}
              alt=""
            />
            <Link to="/accomplishment-timeline" className="bg-[#9F2725] p-2 md:p-3 absolute right-0 md:right-[30px] bottom-[-70px]  md:bottom-[-50px] w-[220px] md:w-[350px]  h-[120px] md:h-[160px] space-y-2 md:space-y-4 cursor-pointer rounded">
              <h1 className="font-semibold text-sm md:text-base text-white">
                Accomplishment Timeline
              </h1>
              <p className="font-normal text-xs md:text-sm text-white">
                Journey through our timeline of triumphs and milestones,
                showcasing our evolution and impact.
              </p>
              <button className="font-normal text-sm capitalize text-white underline">
                know more...
              </button>
            </Link>
          </div>
          <div className="w-5/6 flex items-center justify-center relative cursor-pointer rounded">
            <img
              className="md:w-9/12 hover:scale-105 transition duration-700 ease-in-out hover:text-red rounded"
              src={mission}
              alt=""
            />
            <Link to="/future-graph" className="bg-[#9F2725] p-2 md:p-3 absolute right-0 md:right-[30px] bottom-[-70px]  md:bottom-[-50px] w-[220px] md:w-[350px]  h-[120px] md:h-[160px] space-y-2 md:space-y-4 cursor-pointer rounded">
              <h1 className="font-semibold text-sm md:text-base text-white">
                Accomplishment Saga Ahead
              </h1>
              <p className="font-normal text-xs md:text-sm text-white">
                Explore our future journey of anticipated achievements and
                milestones.
              </p>
              <button className="font-normal text-sm capitalize text-white underline">
                know more...
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Graph