import React from "react";
import "./Appreciation.css";
// import imgtag from "../../image/301.jpg";
import { AppreciationData } from "./AppreciationObj";

const Appreciation = () => {
  return (
    <div className="p-3 sm:p-10 font-OpenSans bg-white text-black">
        <div className="flex flex-col items-center justify-center">
        <h1 className="font-bold text-base sm:text-3xl uppercase text-center pt-5 sm:pt-0">awards and recognition</h1>
        <p className="font-medium  text-xs sm:text-base text-center">Explore our latest achievements and industry accolades, showcasing our commitment to excellence and innovation</p>
        </div>
      <div className="w-full h-full bg-red mt-8 mb-10">
        {AppreciationData &&
          AppreciationData.map((item) => {
            return (
            //   <figure>
            //     <div class="outerBevel">
            //       <div class="flatSurface">
            //         <div class="innerBevel">
                      <img
                        src={item.url}
                        alt={item.name}
                        className=""
                      />
            //         </div>
            //       </div>
            //     </div>
            //   </figure>
            );
          })}
      </div>
    </div>
  );
};

export default Appreciation;
