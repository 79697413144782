import React from "react";

const Garudayaan = () => {
  return (
    <div className="p-5 sm:p-10 font-Montserrat space-y-2 bg-white text-black">
      <p className="font-medium text-base">
        At <a className="text-red font-semibold" href="GARUDAYAAN">GARUDAYAAN</a>, one of Bangalore's top logistics companies, we are
        committed to delivering exceptional logistics solutions. With a team of
        dedicated professionals, we ensure your shipments reach their
        destination on time and in perfect condition. Our range of services
        includes air and ocean freight, ground transportation, warehousing and
        distribution, and customs brokerage. Whether it’s a small package or a
        large container, we have the expertise to handle it.
      </p>
      <p className="font-medium text-base">
        Our advanced technology and real-time tracking systems allow us to keep
        you updated on your shipments every step of the way. We prioritize
        transparency and work closely with each client to tailor our services to
        meet their unique needs. At GARUDAYAAN, we build long-term relationships
        through personalized service and dedication to quality.
      </p>
      <p className="font-medium text-base">
        For more detailed information about our services and how we can assist
        you, please visit our website at <a className="ml-1 text-red font-semibold" href="www.garudayaan.com">www.garudayaan.com</a>.
      </p>
    </div>
  );
};

export default Garudayaan;
