import {
  CREATE_SOCIAL_REQUIEST,
  CREATE_SOCIAL_SUCCESS,
  CREATE_SOCIAL_FAIL,
  GET_ALL_SOCIAL_REQUIEST,
  GET_ALL_SOCIAL_SUCCESS,
  GET_ALL_SOCIAL_FAIL,
  GET_SOCIAL_BY_ID_FAIL,
  GET_SOCIAL_BY_ID_REQUEST,
  GET_SOCIAL_BY_ID_SUCCESS,
  UPDATE_SOCIAL_BY_REQUIEST,
  UPDATE_SOCIAL_BY_SUCCESS,
  UPDATE_SOCIAL_BY_FAIL,
  DELETE_BY_SOCIAL_BY_ID_REQUIEST,
  DELETE_BY_SOCIAL_BY_ID_FAIL,
  DELETE_BY_SOCIAL_BY_ID_SUCCESS,
} from "../constants/socialConstant";
import axios from "axios";

export const createSocialAction = (social) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SOCIAL_REQUIEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post("/api/social/upload", social, config);

    dispatch({
      type: CREATE_SOCIAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SOCIAL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all social

export const getAllSocialAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_SOCIAL_REQUIEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get("/api/social", config);

    // console.log(data);
    dispatch({
      type: GET_ALL_SOCIAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SOCIAL_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getSocialById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SOCIAL_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/social/${id}`, config);

    dispatch({
      type: GET_SOCIAL_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SOCIAL_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update social by id
export const updateSocialbyId = (id,updateSocial) =>async(dispatch)=>{
try {
  dispatch({
    type: UPDATE_SOCIAL_BY_REQUIEST
  })

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const {data} = await axios.patch(`/api/social/${id}`,updateSocial,config)
  dispatch({
    type: UPDATE_SOCIAL_BY_SUCCESS,
    payload: data
  })
} catch (error) {
  dispatch({
    type: UPDATE_SOCIAL_BY_FAIL,
    payload: error.response.data.message
  })
}
}


export const deleteBySocialId = (id) => async (dispatch)=>{
  try {
    dispatch({
      type: DELETE_BY_SOCIAL_BY_ID_REQUIEST
    })

    const {data} = await axios.delete(`/api/social/${id}`)
    dispatch({
      type: DELETE_BY_SOCIAL_BY_ID_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: DELETE_BY_SOCIAL_BY_ID_FAIL,
      payload: error.response.data.message
    })
  }
}