import React, { useEffect } from "react";
import SideBar from "../Dashboard/SideBar";
import { getAllPositionAction } from "../../../redux/action/carresAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const AllJobs = () => {
  const dispatch = useDispatch();
  // const [search,setSearch] = useState()
  // const [filterjob,setFilterJob] = useState([])
  const { allposition, loading } = useSelector((state) => state.jobPosition);
  // console.log(filterjob);


  // useEffect(()=>{
  //   const filterData = allposition && allposition?.filter((job) => {
  //     console.log(job)
  //     return (
  //       job?.name?.toLowerCase().includes(search?.toLowerCase())
  //     );
  //   });
  //   setFilterJob(filterData)
  // },[allposition,search])

  useEffect(() => {

    dispatch(getAllPositionAction());
  }, [dispatch]);
  return (
    <div className="flex font-Montserrat">
      <SideBar />
      <div className="p-5 w-full">
        <div>
          <h1 className="font-semibold text-base ">All Posted Job </h1>
        </div>
        <div className="flex flex-wrap mt-5 overflow-y-scroll h-[500px] w-full">
          {/* {loading ? (
            <h1>Loading...</h1>
          ) : (
            allposition &&
            allposition.map((job) => {
              return (
                <Link
                  to={`/dashboard/all-jobs/${job._id}`}
                  className="rounded overflow-hidden shadow-lg m-1 w-[310px]"
                > */}

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
            <div className="pb-4 bg-white dark:bg-gray-900">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  name=""
                  className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for items"
                  // onChange={(e)=>setSearch(e.target.value)}
                />
              </div>
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Job Title
                  </th>
                  <th scope="col" className="px-8 py-3">
                    Posted Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Experience
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Opening
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? <h1>loading...</h1> : allposition &&
                  allposition.map((job) => {
                    return (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                        >
                          {job.name}
                        </th>
                        <td className="px-8 py-4">
                          {new Date(job.createdAt)
                            .toISOString()
                            .substring(0, 10)}
                        </td>
                        <td className="px-6 py-4">{job.location}</td>
                        <td className="px-6 py-4">{job.experience}</td>
                        <td className="px-6 py-4">{job.opening}</td>
                        <td className="px-6 py-4">
                          <Link
                            to={`/dashboard/all-jobs/${job._id}`}
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >
                            <FaEdit size={20} color="green" />
                          </Link>
                        </td>
                        <td className="px-6 py-4">
                          <Link
                            to="#"
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >
                            <MdDeleteForever size={25} color="red" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {/* <div class="max-w-sm rounded overflow-hidden">
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">{job.name}</div>
              <p class="text-gray-700 text-base">
                {job?.details.map((con) => {
                  return (
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(truncateText(con, 25)),
                      }}
                    />
                  );
                })}{" "}
              </p>
            </div>
            <div class="px-6 pt-4 pb-2">
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Opening : {job.opening}
              </span>
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Location : {job.location}
              </span>
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Experience : {job.experience}
              </span>
            </div>
          </div> */}

          {/* </Link>
              );
            })
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AllJobs;
