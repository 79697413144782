import React from "react";
// import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";

const UpdateApplicationStatus = () => {
  // const { statusUpdate } = useSelector((state) => state.jobPosition);
  // const { update } = useParams();

  return <div>Hello</div>;
};

export default UpdateApplicationStatus;
