import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  LOAD_USER_REQUIEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  GET_ALL_USERS_REQUIEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  USER_LOG_OUT_REQUEST,
  USER_LOG_OUT_SUCCESS,
  USER_LOG_OUT_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  UPDATE_PROFILE_DEATIL_REQUEST,
  UPDATE_PROFILE_DEATIL_SUCCESS,
  UPDATE_PROFILE_DEATIL_FAIL,
} from "../constants/authConstants";
import {
  GET_CARRER_APPLICATION_BY_USER_ID_FAIL,
  GET_CARRER_APPLICATION_BY_USER_ID_REQUEST,
  GET_CARRER_APPLICATION_BY_USER_ID_SUCCESS,
} from "../constants/carrersConstant";

export const createUserReducer = (
  state = { isAuthenticated: true },
  action
) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
    case USER_LOGIN_REQUEST:
    case LOAD_USER_REQUIEST:
    case USER_LOG_OUT_REQUEST:
    case UPDATE_PROFILE_DEATIL_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };

    case USER_REGISTER_SUCCESS:
    case USER_LOGIN_SUCCESS:
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
      };

    case USER_LOG_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
        isAuthenticated: false,
      };

    case UPDATE_PROFILE_DEATIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case USER_REGISTER_FAIL:
    case USER_LOGIN_FAIL:
    case LOAD_USER_FAIL:
    case USER_LOG_OUT_FAIL:
      case UPDATE_PROFILE_DEATIL_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getALLUsersReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUIEST:
      return {
        ...state,
        loading: true,
        // isAuthenticated:false
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        // isAuthenticated:true,
        allusers: action.payload,
      };

    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        // isAuthenticated:false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getApplicationByUserId = (state = {}, action) => {
  switch (action.type) {
    case GET_CARRER_APPLICATION_BY_USER_ID_REQUEST:
    case USER_FORGOT_PASSWORD_REQUEST:
    case USER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        // isAuthenticated: false
      };

    case GET_CARRER_APPLICATION_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        // isAuthenticated: true,
        userCarrerProfile: action.payload,
      };

    case USER_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case USER_RESET_PASSWORD_SUCCESS:
      // console.log(action.payload.message)
      return {
        ...state,
        loading: false,
        success: action.payload.message,
      };

    case GET_CARRER_APPLICATION_BY_USER_ID_FAIL:
    case USER_FORGOT_PASSWORD_FAIL:
    case USER_RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        // isAuthenticated: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
