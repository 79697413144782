import { configureStore } from '@reduxjs/toolkit'
import {combineReducers} from "redux"
import { createSocialReducers } from './redux/reducers/socialReducers'
import { getAllCategory } from './redux/reducers/categoryReducers'
import { applyJobPost, getAllApplicationReducer, getAllPosition, getCarrerbyId } from './redux/reducers/carrersReducers'
import { thunk } from 'redux-thunk'
import { createUserReducer, getALLUsersReducers, getApplicationByUserId } from './redux/reducers/authReducers'
import { createBlogs, getAllProductCategoryReducers, getBlogByUserId } from './redux/reducers/BlogsReducers'


const rootReducer = combineReducers({
  createSocial: createSocialReducers,
  socialCategory: getAllCategory,
  jobPosition: getAllPosition,
  userCarrerProfile: getApplicationByUserId,
  applyed: applyJobPost,
  carrerId: getCarrerbyId,
  user: createUserReducer,
  allUsers: getALLUsersReducers,
  allApplication: getAllApplicationReducer,
  ProductCategory: getAllProductCategoryReducers,
  blogs: createBlogs,
  blogByUserId: getBlogByUserId
});

const initalState = {}

const middleware = [thunk];

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initalState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
});


export default store