export const APPLY_POSITION_REQUEST = "APPLY_POSITION_REQUEST"
export const APPLY_POSITION_SUCCESS = "APPLY_POSITION_SUCCESS"
export const APPLY_POSITION_FAIL = "APPLY_POSITION_FAIL"

export const GET_ALL_POSITION_REQUEST = "GET_ALL_POSITION_REQUEST"
export const GET_ALL_POSITION_SUCCESS = "GET_ALL_POSITION_SUCCESS"
export const GET_ALL_POSITION_FAIL = "GET_ALL_POSITION_FAIL"



export const GET_POSITION_DETAILS_REQUEST = "GET_POSITION_DETAILS_REQUEST"
export const GET_POSITION_DETAILS_SUCCESS = "GET_POSITION_DETAILS_SUCCESS"
export const GET_POSITION_DETAILS_FAIL = "GET_POSITION_DETAILS_FAIL"



// get all appication
export const GET_ALL_APPLICATION_REQUEST = "GET_ALL_APPLICATION_REQUEST"
export const GET_ALL_APPLICATION_SUCCESS = "GET_ALL_APPLICATION_SUCCESS"
export const GET_ALL_APPLICATION_FAIL = "GET_ALL_APPLICATION_FAIL"


// get carrer by id 
export const GET_CARRER_BY_ID_REQUEST = "GET_CARRER_BY_ID_REQUEST"
export const GET_CARRER_BY_ID_SUCCESS = "GET_CARRER_BY_ID_SUCCESS"
export const GET_CARRER_BY_ID_FAIL = "GET_CARRER_BY_ID_FAIL"

// get carrer application by user id
export const GET_CARRER_APPLICATION_BY_USER_ID_REQUEST = "GET_CARRER_APPLICATION_BY_USER_ID_REQUEST"
export const GET_CARRER_APPLICATION_BY_USER_ID_SUCCESS = "GET_CARRER_APPLICATION_BY_USER_ID_SUCCESS"
export const GET_CARRER_APPLICATION_BY_USER_ID_FAIL = "GET_CARRER_APPLICATION_BY_USER_ID_FAIL"


// get number of Applicants applied for the one job
export const GET_APPLICANTS_APPLIED_REQUEST ="GET_APPLICANTS_APPLIED_REQUEST"
export const GET_APPLICANTS_APPLIED_SUCCESS ="GET_APPLICANTS_APPLIED_SUCCESS"
export const GET_APPLICANTS_APPLIED_FAIL ="GET_APPLICANTS_APPLIED_FAIL"

// create job
export const CREATE_JOB_POST_REQUEST = "CREATE_JOB_POST_REQUEST"
export const CREATE_JOB_POST_SUCCESS = "CREATE_JOB_POST_SUCCESS"
export const CREATE_JOB_POST_FAIL = "CREATE_JOB_POST_FAIL"

// update job post
export const UPDATE_JOB_POST_REQUEST = "UPDATE_JOB_POST_REQUEST"
export const UPDATE_JOB_POST_SUCCESS = "UPDATE_JOB_POST_SUCCESS"
export const UPDATE_JOB_POST_FAIL = "UPDATE_JOB_POST_FAIL"


// get all job category type
export const GET_JOB_CATEGORY_REQUEST = "GET_JOB_CATEGORY_REQUEST"
export const GET_JOB_CATEGORY_SUCCESS = "GET_JOB_CATEGORY_SUCCESS"
export const GET_JOB_CATEGORY_FAIL = "GET_JOB_CATEGORY_FAIL"

// update carrer applications
export const UPDATE_CARRER_APPLICATION_STATUS_REQUEST = "UPDATE_CARRER_APPLICATION_STATUS_REQUEST"
export const UPDATE_CARRER_APPLICATION_STATUS_SUCCESS = "UPDATE_CARRER_APPLICATION_STATUS_SUCCESS"
export const UPDATE_CARRER_APPLICATION_STATUS_FAIL = "UPDATE_CARRER_APPLICATION_STATUS_FAIL"


// department positions category type
export const CREATE_JOB_DEPARTMENT_REQUEST = "CREATE_JOB_DEPARTMENT_REQUEST"
export const CREATE_JOB_DEPARTMENT_SUCCESS = "CREATE_JOB_DEPARTMENT_SUCCESS"
export const CREATE_JOB_DEPARTMENT_FAIL = "CREATE_JOB_DEPARTMENT_FAIL"
