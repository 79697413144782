import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUser } from "../../redux/action/authAction";
import { IoEyeSharp } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { toast } from "react-toastify";

const Register = () => {
  const { user, error } = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const [register, setRegister] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const dispatch = useDispatch();

  const onHandleChange = (e) => {
    setRegister({ ...register, [e.target.name]: e.target.value });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    console.log(register);

    const user = {
      name: register.name,
      email: register.email,
      mobile: Number(register.mobile),
      password: register.password,
    };
    // const myform = new FormData();
    // myform.append("email", register.email);
    // myform.append("mobile", register.mobile);
    // myform.append("password", register.password);
    dispatch(createUser(user));
  };

  useEffect(() => {
    if (user) {
      toast.success("User registration successfull");
      navigate("/profile");
    }
  }, [dispatch, navigate, user, error]);

  return (
    <div className="font-Montserrat">
      <section className="bg-white text-black">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create an account
              </h1>
              <form
                className="space-y-4 md:space-y-2"
                onSubmit={onHandleSubmit}
              >
                <div>
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Full Name"
                    value={register.name}
                    onChange={onHandleChange}
                  />
                </div>
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="email"
                    value={register.email}
                    onChange={onHandleChange}
                  />
                </div>
                <div>
                  <label
                    for="mobile"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Mobile
                  </label>
                  <input
                    type="number"
                    name="mobile"
                    id="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="number"
                    value={register.mobile}
                    onChange={onHandleChange}
                  />
                </div>
                <div className="relative">
                  <label
                    for="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    password
                  </label>
                  <input
                    type={toggle ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Password"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={register.password}
                    onChange={onHandleChange}
                  />
                  <div className="absolute top-9 right-5 cursor-pointer">
                    {toggle ? (
                      <IoEyeSharp
                        size={25}
                        onClick={() => setToggle(!toggle)}
                      />
                    ) : (
                      <IoMdEyeOff
                        size={25}
                        onClick={() => setToggle(!toggle)}
                      />
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full text-white rounded font-medium  px-5 py-2.5 text-center bg-red"
                >
                  Create an account
                </button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="font-medium text-red hover:underline dark:text-gray"
                  >
                    Login
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
