// create blog request
export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST"
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS"
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL"

// update blog by id
export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST"
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS"
export const UPDATE_BLOG_FAIL = "UPDATE_BLOG_FAIL"


// get all blogs
export const GET_ALL_BLOGS_REQUEST = "GET_ALL_BLOGS_REQUEST"
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS"
export const GET_ALL_BLOGS_FAIL = "GET_ALL_BLOGS_FAIL"

// blog get by blog id
export const GET_BLOG_DETAILS_BY_ID_REQUEST = "GET_BLOG_DETAILS_BY_ID_REQUEST"
export const GET_BLOG_DETAILS_BY_ID_SUCCESS = "GET_BLOG_DETAILS_BY_ID_SUCCESS"
export const GET_BLOG_DETAILS_BY_ID_FAIL = "GET_BLOG_DETAILS_BY_ID_FAIL"


// create product category 
export const CREATE_PRODUCT_CATEGORY_REQUEST = "CREATE_PRODUCT_CATEGORY_REQUEST"
export const CREATE_PRODUCT_CATEGORY_SUCCESS = "CREATE_PRODUCT_CATEGORY_SUCCESS"
export const CREATE_PRODUCT_CATEGORY_FAIL = "CREATE_PRODUCT_CATEGORY_FAIL"

// get all product category 
export const GET_ALL_PRODUCT_CATEGORY_REQUEST = "GET_ALL_PRODUCT_CATEGORY_REQUEST"
export const GET_ALL_PRODUCT_CATEGORY_SUCCESS = "GET_ALL_PRODUCT_CATEGORY_SUCCESS"
export const GET_ALL_PRODUCT_CATEGORY_FAIL = "GET_ALL_PRODUCT_CATEGORY_FAIL"


// get blog by user Id in dashboard
export const GET_BOG_BY_USER_ID_REQUEST = "GET_BOG_BY_USER_ID_REQUEST"
export const GET_BOG_BY_USER_ID_SUCCESS = "GET_BOG_BY_USER_ID_SUCCESS"
export const GET_BOG_BY_USER_ID_FAIL = "GET_BOG_BY_USER_ID_FAIL"

// upload blog editor image 
export const UPLOAD_BLOG_IMAGE_REQUEST = "UPLOAD_BLOG_IMAGE_REQUEST"
export const UPLOAD_BLOG_IMAGE_SUCCESS = "UPLOAD_BLOG_IMAGE_SUCCESS"
export const UPLOAD_BLOG_IMAGE_FAIL = "UPLOAD_BLOG_IMAGE_FAIL"
