import React from "react";

const WardWizard = () => {
  return (
    <div className="p-5 sm:p-10 font-Montserrat space-y-2 bg-white text-black">
      <p className="font-medium text-base">
        Wardwizard Innovations & Mobility Limited is a pioneering company in the
        electric vehicle (EV) sector in India. It was established in 2016 and
        has since become the first EV manufacturer listed on the Bombay Stock
        Exchange (BSE), boasting a current market capitalization of around
        ₹1,800 crore. The company focuses on creating sustainable mobility
        solutions through its flagship brand, Joy e-Bike, which offers a range
        of low-speed and high-speed electric bikes.
      </p>
      <p className="font-medium text-base">
        Wardwizard aims to lead the EV market by committing to innovation and
        quality, with plans to capture 25% of the electric two-wheeler market by
        2030. Their strategy includes developing new models with components made
        entirely in India, aligning with the "Make in India" initiative​(
        Wardwizard Mobility ).
      </p>
      <p className="font-medium text-base">
        With a strong emphasis on teamwork and dedication, Wardwizard believes
        in nurturing entrepreneurial talent to drive the growth of eco-friendly
        mobility solutions. The company's vision includes significantly reducing
        air pollution caused by fuel-driven vehicles, promoting a cleaner and
        greener future for India​( Wardwizard Mobility ).
      </p>
      <p className="font-medium text-base">
        For more detailed information about Wardwizard and its initiatives, you
        can visit their official website -
        <a href="https://wardwizard.in" className="ml-1 text-red font-semibold">
            www.wardwizard.in
        </a>
      </p>
    </div>
  );
};

export default WardWizard;
