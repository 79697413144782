import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { loadUser } from "../../redux/action/authAction";
import Cookies from "js-cookie";

const ProtectedRoutes = ({ children }) => {
  let { loading, isAuthenticated } = useSelector((state) => state.user);
  // console.log(isAuthenticated)
  const dispatch = useDispatch()
  // const location = useLocation()
  // console.log(location)
  // console.log(user)
// console.log(user)
  // if(JSON.parse(localStorage.getItem('user_info')).isAuthenticated) {
  //   isAuthenticated = JSON.parse(localStorage.getItem('user_info')).isAuthenticated;
  // }
  // console.log(isAuthenticated);
  const location = useLocation();

  useEffect(() => {
    // Check authentication on component mount
    const token = Cookies.get('token');
    if (token) {
      dispatch(loadUser()); // Load user details if token exists
    }
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }


  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }
  return <Fragment>{children ? children : <Outlet />}</Fragment>;
};

export default ProtectedRoutes;
