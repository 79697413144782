import tv85 from "../../image/85 inches1.jpeg";
import washing_machine from "../../image/17.jpg";
import dishwasher from "../../image/09.jpg";
import ref from "../../image/08.jpg";

export const premiumData = [
    {
        id: 0,
        url: "https://gshoppi.com/product/Gplus-8-kg-Fully-Automatic-Front-Load-Washing-Machine-silver/6581475ca02a3e7d21ac6e81",
        imageUrl: washing_machine
    },
    {
        id: 1,
        url: "https://gshoppi.com/product/GPLUS-528-L-FRENCH-DOOR-BOTTOM-FREZZER-Refrigerator-(Silver-GM-528W)/65eae981145ae609d59a8577",
        imageUrl: ref
    },
    {
        id: 2,
        url: "https://gshoppi.com/product/GPLUS-DISH-WASHER-16-PLACE-SETTINGS-black-(GM-DW-ULTRAX16)/658bbdfb0d16b48350c46ce4",
        imageUrl: dishwasher
    },
    {
        id: 3,
        url: "https://gshoppi.com/product/GPLUS-QLED-214-Cm-(85-Inches)-Ultra-HD-4K/65d9baf529cf4480e2ffdabc",
        imageUrl: tv85
    },
]