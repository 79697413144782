import React from "react";

const BusinessOverview = () => {
  return (
    <div className="pl-3 md:pl-10 pr-3 md:pr-10 pt-10 pb-10 font-Montserrat bg-white text-black">
        <div className="flex items-start justify-start">
            <h1 className="font-bold text-2xl md:text-3xl">BUSINESS OVERVIEW</h1>
        </div>
      <div className="space-y-2 mt-5 md:mt-10">
        <div>
          <h1 className="font-bold text-base md:text-lg">Company Background</h1>
          <p className="font-medium  text-sm md:text-base mt-1">
            We are pioneering brand in the home appliances domain, established
            with a mission to revolutionize household comfort and convenience.
            Over the span of just seven years, we have expanded its market
            presence significantly, primarily across the Southern region of
            India.
          </p>
        </div>

        <div className="pt-3">
          <h1 className="font-bold text-base md:text-lg">Mission and Vision</h1>
          <p className="font-medium  text-sm md:text-base mt-1">
            We dedicated to delivering high-quality, reliable, and
            technologically advanced home appliances we enhance daily life while
            being environmentally sustainable. Our vision is to meet the
            evolving needs of consumers with innovative solutions that ensure
            comfort, convenience, and well-being.
          </p>
        </div>

        <div className="pt-3">
          <h1 className="font-bold text-base md:text-lg">Products and Services</h1>
          <p className="font-medium  text-sm md:text-base mt-1">
            The company offers a comprehensive range of essential household
            items, including:
          </p>

          <div className="flex space-x-2 flex-wrap">
            <h1 className="font-medium  text-sm md:text-base mt-1">Washing machines</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Refrigerators</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Dishwashers</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">LED TVs</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Coolers</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Fans</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Rice cookers</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Mixer grinders</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Wet grinders</h1>
            <h1 className="font-bold text-lg">|</h1>
            <h1 className="font-medium  text-sm md:text-base mt-1">Cook tops</h1>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h1 className="font-bold text-base md:text-lg">Market Position</h1>
        <p className="font-medium  text-sm md:text-base mt-1">
          Garuda Mart India Pvt. Ltd. has established a robust network of over
          1200 dealers, primarily in the Southern region of India, and has
          garnered the trust of more than 200,000 satisfied customers. The
          company's strategic focus on quality, innovation, and customer
          satisfaction has helped it gain significant market share and brand
          recognition.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-base md:text-lg">Business Model</h1>
        <p className="font-medium  text-sm md:text-base mt-1">
          The company generates revenue by selling a wide array of household
          appliances through its extensive dealer network. It emphasizes
          technological superiority and environmental sustainability to
          differentiate its products in the competitive market.
        </p>
      </div>

      <div className="space-y-3 mt-7">
        <h1 className="font-bold text-base md:text-lg">Strategic Goals</h1>
        <div className="flex  ">
          <h1 className="w-3/12 font-semibold text-sm">Expansion</h1>
          <span>:</span>
          <p className="w-10/12 md:w-11/12 ml-3 font-medium  text-sm md:text-base mt-1">
            The company is focused on expanding its market reach within India
            and exploring opportunities for global expansion to enhance its
            brand presence and capitalize on new growth avenues.
          </p>
        </div>

        <div className="flex  ">
          <h1 className="w-3/12 font-semibold text-sm">Innovation</h1>
          <span>:</span>
          <p className="w-10/12 md:w-11/12 ml-3 font-medium  text-sm md:text-base mt-1 ">
            Continuous investment in research and development to stay ahead of
            industry trends and integrate advanced technologies into its product
            offerings.
          </p>
        </div>

        <div className="flex  ">
          <h1 className="w-3/12 font-semibold text-sm">Quality Improvement</h1>
          <span>:</span>
          <p className="w-10/12 md:w-11/12 ml-3 font-medium  text-sm md:text-base mt-1" >
            Maintaining stringent quality control measures to further reduce the
            complaint ratio from 0.25% to 0.1%. Financial Performance
          </p>
        </div>
        <p className="font-medium  " style={{ fontSize: "15px" }}>
          Our brand has seen significant growth in revenue and
          market share, driven by its commitment to quality and customer
          satisfaction. The positive response from over 200,000 customers
          underscores the company's successful market penetration and
          operational excellence.
        </p>

        <div className="space-y-5 pt-3">
          <div className="">
            <h1 className="font-bold text-base md:text-lg">Leadership</h1>
            <p className="font-medium tex-base mt-1">
              The company's success is largely attributed to the visionary
              leadership of Chairman and Managing Director Mr. Venkataramana Revuru,
              whose dedication and strategic direction have been instrumental in
              scaling the company to new heights.
            </p>
          </div>

          <div>
            <h1 className="font-bold text-base md:text-lg">
              Commitment to Corporate Responsibility
            </h1>
            <p className="font-medium tex-base mt-1">
              Garuda Mart India Pvt. Ltd. is committed to corporate social
              responsibility, focusing on producing environmentally sustainable
              products and supporting the overall well-being of its consumers.
            </p>
          </div>

          <div>
            <h1 className="font-bold text-base md:text-lg">Future Outlook</h1>
            <p className="font-medium tex-base mt-1">
              With a strong foundation in the Indian market, the company aims to
              explore international markets and continue its journey of
              innovation and excellence, ensuring high-quality solutions for
              everyday household needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessOverview;
