import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userLogin } from "../../redux/action/authAction";
import { useDispatch,useSelector } from "react-redux";
import { IoEyeSharp } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { toast } from "react-toastify";


const Login = () => {
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState('');
  const [toggle,setToggle] = useState(false);
  const dispatch= useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let { error, isAuthenticated } = useSelector(
    (state) => state.user
  );


  const onHandleSubmit = (e) =>{
    e.preventDefault()
    const formData = {
      email: email,
      password: password
    }
    dispatch(userLogin(formData))
    toast.success("login successfull");
  }

  const redirect = location.search ? location.search.split("=")[0] : "/profile";
// console.log(user)

  useEffect(()=>{
    if(isAuthenticated){
      navigate(redirect);
      // toast.success("");
    }else{
      toast.error(error)
    }

    // if (error) {
    //   toast.error(error);
    // }
    
  },[dispatch,isAuthenticated,redirect,navigate,error])


  return (
    <div>
      <section className="bg-white text-black ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={onHandleSubmit}>
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                  />
                </div>
                <div className="relative">
                  <label
                    for="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type={toggle ? "text" : "password"}
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                  />
                   <div className="absolute top-9 right-5 cursor-pointer">
                    {toggle ? (
                      <IoEyeSharp
                        size={25}
                        onClick={() => setToggle(!toggle)}
                      />
                    ) : (
                      <IoMdEyeOff
                        size={25}
                        onClick={() => setToggle(!toggle)}
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <Link
                    to="/forgot-password"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full text-white   px-5 py-2.5 text-center bg-red"
                >
                  Sign in
                </button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don’t have an account yet?
                  <Link
                    to="/signup"
                    class="ml-1 font-medium text-red hover:underline dark:text-gray"
                  >
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
