import React, { useEffect, useState } from "react";
import { createDepartment } from "../../../redux/action/carresAction";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";

const CreateDepartment = () => {
  const dispatch = useDispatch();
  const { departmentCreate, error } = useSelector((state) => state.jobPosition);
  const [department, setDepartment] = useState();
  const onHandleSubmitDepartment = (e) => {
    e.preventDefault();
    dispatch(createDepartment({ name: department }));
  };

  useEffect(() => {
    if (departmentCreate) {
      alert("department created successfull");
    }
    if (error) {
      alert(error);
    }
  }, [dispatch,departmentCreate,error]);

  return (
    <form
      class="h-[200px] w-full bg-white shadow-2xl z-20 p-5"
      onSubmit={onHandleSubmitDepartment}
    >
      <div class="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="department"
          id="floating_email"
          value={department}
          class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          onChange={(e) => setDepartment(e.target.value)}
        />
        <label
          for="floating_email"
          class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Add type of department
        </label>
      </div>

      <button
        type="submit"
        class="text-white bg-red focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Submit
      </button>
    </form>
  );
};

export default CreateDepartment;
