import magzine1 from "../../image/300.jpg";
import magzine2 from "../../image/301.jpg";
import magzine3 from "../../image/302.jpg";
import PDF from "../Brand.pdf"; // Import your PDF file
import magazine4 from "../../image/303.jpg"


export const Journal = [
    {
        id: 0,
        image: magazine4,
        pdfFile: "",
        name: "2022 edition",
        url: "https://www.outlookbusiness.com/enterprise-3/outlook-business-initiative-148/garuda-mart-scripting-an-indigenous-entreprenurial-story-6609"
    },
    {
        id: 1,
        image: magzine1,
        pdfFile: "",
        name: "2023 edition",
        url: "https://successmagazine.in/an-educator-turned-sociopreneur-creating-sustainable-products-to-cater-to-the-society/"
        
    },
    {
        id: 2,
        image: magzine2,
        pdfFile:PDF ,
        name: "2023 edition",
        url: "https://brandzmagazine.com/an-educator-turned-sociopreneur-creating-sustainable-products-to-cater-to-the-society/"
    },
    {
        id: 3,
        image: magzine3,
        pdfFile: "",
        name: "2024 edition",
        url: "https://businessconnectindia.in/garuda-mart-india-pvt-ltd/"
    },
    
]