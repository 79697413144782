import {GET_SOCIAL_CATEGORY_REQUEST,GET_SOCIAL_CATEGORY_SUCCESS,GET_SOCIAL_CATEGORY_FAIL} from "../constants/categoryConstant"
import axios from "axios"


export const getSocialCategory = () =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_SOCIAL_CATEGORY_REQUEST
        })

        const {data} = await axios.get("/api/social-category/all-category")

        dispatch({
            type: GET_SOCIAL_CATEGORY_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_SOCIAL_CATEGORY_FAIL,
            payload: error.response.data.message
        })
    }
}
