import {
  GET_ALL_PRODUCT_CATEGORY_REQUEST,
  GET_ALL_PRODUCT_CATEGORY_SUCCESS,
  GET_ALL_PRODUCT_CATEGORY_FAIL,
  GET_ALL_BLOGS_REQUEST,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOGS_FAIL,
  GET_BLOG_DETAILS_BY_ID_REQUEST,
  GET_BLOG_DETAILS_BY_ID_SUCCESS,
  GET_BLOG_DETAILS_BY_ID_FAIL,
  CREATE_BLOG_REQUEST,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_FAIL,
  UPDATE_BLOG_REQUEST,
  UPDATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAIL,
  GET_BOG_BY_USER_ID_FAIL,
  GET_BOG_BY_USER_ID_SUCCESS,
  GET_BOG_BY_USER_ID_REQUEST,
  UPLOAD_BLOG_IMAGE_REQUEST,
  UPLOAD_BLOG_IMAGE_SUCCESS,
  UPLOAD_BLOG_IMAGE_FAIL,
} from "../constants/BlogsConstants";

export const createBlogs = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BLOG_REQUEST:
    case UPDATE_BLOG_REQUEST:
      case UPLOAD_BLOG_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_BLOG_SUCCESS:
      return {
        ...state,
        loading: true,
        success: action.payload,
      };

    case UPDATE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

      case UPLOAD_BLOG_IMAGE_SUCCESS:
        return{
          ...state,
          loading: false,
          image: action.payload
        }

    case CREATE_BLOG_FAIL:
    case UPDATE_BLOG_FAIL:
      case UPLOAD_BLOG_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getAllProductCategoryReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT_CATEGORY_REQUEST:
    case GET_ALL_BLOGS_REQUEST:
    case GET_BLOG_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        productCategory: action.payload,
      };

    case GET_ALL_BLOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        allblogs: action.payload,
      };

    case GET_BLOG_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        blogId: action.payload,
      };

    case GET_ALL_PRODUCT_CATEGORY_FAIL:
    case GET_ALL_BLOGS_FAIL:
    case GET_BLOG_DETAILS_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// get blog by user id

export const getBlogByUserId = (state = {}, action) => {
  switch (action.type) {
    case GET_BOG_BY_USER_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_BOG_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        blogsByUser: action.payload,
      };

    case GET_BOG_BY_USER_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
