export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"


export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"


export const LOAD_USER_REQUIEST = "LOAD_USER_REQUIEST"
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL = "LOAD_USER_FAIL"

// all users
export const GET_ALL_USERS_REQUIEST = "GET_ALL_USERS_REQUIEST"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL"

// log out
export const USER_LOG_OUT_REQUEST = "USER_LOG_OUT_REQUEST"
export const USER_LOG_OUT_SUCCESS = "USER_LOG_OUT_SUCCESS"
export const USER_LOG_OUT_FAIL = "USER_LOG_OUT_FAIL"

// forgot password
export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST"
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS"
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL"



// reset password
export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST"
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS"
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL"

// update profile 
export const UPDATE_PROFILE_DEATIL_REQUEST = "UPDATE_PROFILE_DEATIL_REQUEST"
export const UPDATE_PROFILE_DEATIL_SUCCESS = "UPDATE_PROFILE_DEATIL_SUCCESS"
export const UPDATE_PROFILE_DEATIL_FAIL = "UPDATE_PROFILE_DEATIL_FAIL"
