import React from "react";
// import logo from "../../image/logo-garudmart.png";
// import facebook from "../../image/facebook.png";
// import twitter from "../../image/twitter.png";
// import linkedin from "../../image/linkedin.png";
// import youtube from "../../image/youtube.png";
// import instagram from "../../image/instagram.png";
import { Link } from "react-router-dom";
import "./Footer.css";
import bg from "../../image/Header.jpg";
// import logo from "../"

const Footer = () => {
  const date = new Date()
  const years = date.getFullYear()
  return (
    <>
      <div
        className="flex flex-col bg-black text-white w-full h-[160px] md:h-auto pt-5 pb-5 justify-center  font-roboto"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <div className="flex flex-col w-full md:w-3/12 pl-5 sm:pl-20 pr-3 sm:pr-20 md:p-0">
          <div className="flex items-center">
            {/* <img className="w-10 sm:w-15" src={logo} alt="logo" /> */}
            {/* <h1 className="font-bold text-base sm:text-xl">
              Garuda Mart India Pvt Ltd.
            </h1>
          </div>
          <p className="font-normal text-xs sm:text-sm mr-5">
            Survey No. 183/1, Lake Side Road, Behind NCC Apartment, Mahadevapura
            Bangalore(KA). 560048,
          </p>
          <h1 className="mt-2 font-bold text-[12px]">1800 12398 6666</h1>
          <p className="font-medium text-[12px] sm:text-sm">admin@garudamartindia.com</p>
        </div>  */}
        <div className="mt-2 sm:mt-3 flex  justify-between pr-10 sm:justify-around w-full  pl-5 sm:p-20 md:p-0">
          <div className="">
            <h1 className="font-semibold text-[12px] sm:text-lg">Company</h1>
            <div className="link  flex flex-col sm:flex-row  space-x-0 sm:space-x-3">
              <li className="leading-[16px] no-underline">
                <Link to="/corporate" className="font-medium capitalize text-[10px] sm:text-sm ">
                  Corporate
                </Link>
              </li>
              <li className="leading-[16px] no-underline">
                <Link
                  to="/corporate/business-overview"
                  className="font-medium capitalize text-[10px] sm:text-sm "
                >
                  business overview
                </Link>
              </li>

              <li className="leading-[16px] no-underline">
                <Link to="/corporate/company-profile" className="font-medium capitalize text-[10px] sm:text-sm ">
                  Company Profile
                </Link>
              </li>
              
              {/* <li className="leading-[16px] no-underline">
                <Link to="/ebikes" className="font-medium capitalize text-[10px] sm:text-sm ">
                  EV Mobility
                </Link>
              </li> */}
              <li className="leading-[16px] no-underline">
                <Link to="/contact" className="font-medium capitalize text-[10px] sm:text-sm ">
                  Contact Us
                </Link>
              </li>
              <li className="leading-[16px] no-underline">
                <Link to="/blogs" className="font-medium capitalize text-[10px] sm:text-sm ">
                  Blogs
                </Link>
              </li>
            </div>
          </div>
          <div>
            <h1 className="font-semibold text-[12px] sm:text-lg  sm:mt-0 capitalize ">
              Useful Links
            </h1>
            <div className="link  flex flex-col sm:flex-row space-x-0 sm:space-x-3">
              <li className="leading-[16px] no-underline">
                <Link
                  to="/privacy-policy"
                  className="font-medium capitalize text-[10px] sm:text-sm "
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="leading-[16px] no-underline">
                <Link
                  to="/terms-conditions"
                  className="font-medium capitalize text-[10px] sm:text-sm "
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="leading-[16px] no-underline">
                <Link to="#" className="font-medium capitalize text-[10px] sm:text-sm ">
                  Disclaimer
                </Link>
              </li>
              <li className="leading-[16px] no-underline">
                <Link to="#" className="font-medium capitalize text-[10px] sm:text-sm ">
                  Returns & Refunds
                </Link>
              </li>
            </div>
          </div>
        </div>
        <div className="w-full  flex items-center justify-center pt-5">
        <h1 className="text-[10px] sm:text-base">Copyright</h1>
        <p className="ml-2 mr-2">©</p>
        <p>{years}</p>
        <p className="text-white text-[10px] sm:text-base ml-2">
          Garudamart India Pvt Ltd.
        </p>
      </div>
       
      </div>
      {/* <div className="w-full h-[1px] opacity-20 bg-black "></div> */}
      
    </>
  );
};

export default Footer;
