import {
  GET_SOCIAL_CATEGORY_REQUEST,
  GET_SOCIAL_CATEGORY_SUCCESS,
  GET_SOCIAL_CATEGORY_FAIL,
} from "../constants/categoryConstant";

export const getAllCategory = (state = {}, action) => {
  switch (action.type) {
    case GET_SOCIAL_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SOCIAL_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };

    case GET_SOCIAL_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
