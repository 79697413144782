import React from "react";
import img from "../../image/contact-bg.png";
import Card from "./Card/Card";

const Contact = () => {
  return (
    <div className="w-full min-h-screen bg-white text-black" id="contact">
      <div
        className="w-full  lg:min-h-screen  flex  flex-col  pt-[5rem]"
        style={{ backgroundImage: `url(${img})`, backgroundPosition: "center" }}
      >
        <h1 className="text-center text-xl  font-bold uppercase font-roboto ">
          Contact Us
        </h1>
        {/* <h1 className="font-bold text-xl sm:text-4xl text-center  lg:w-[50rem] font-roboto leading-tight pl-5 pr-5 ">
          GET A FREE CONSULTATION CALL FROM OUR EXPERT TEAM
        </h1> */}
        <div className="">
          <Card />
        </div>
      </div>
      <div className="w-full ">
        <div className="rounded-xl sm:m-[1rem] sm:m-[4rem]  flex  items-center lg:flex-row justify-between sm:pt-20  pl-5 pr-5 pb-10">
          <div className="sm:pr-3  w-full ">
            <div>
              {/* <iframe
                className="rounded-xl w-full h-[350px] sm:h-[450px] mt-10 lg:mt-0"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=420&amp;height=400&amp;hl=en&amp;q=%20Besides%20NCC%20Apartments,%20Survey%20No.%20185/1,%20Lake%20Side%20Road,%20Outer%20Ring%20Rd,%20Mahadevapura,%20Bengaluru,%20Karnataka%20560048+(garuda%20mart%20india%20pvt%20ltd)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                title="map"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">
                  measure area map
                </a>
              </iframe> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15527.897094297618!2d77.9760188!3d13.3518758!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13f673ed491b%3A0xdfb01c46522a3f1b!2sGaruda%20Mart%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1727525185441!5m2!1sen!2sin"
                className="rounded-xl w-full h-[350px] sm:h-[450px] mt-10 lg:mt-0"
                allowfullscreen=""
                loading="lazy"
                title="map"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
