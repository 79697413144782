import React, { useEffect } from "react";
import SideBar from "../Dashboard/SideBar";
import { Link } from "react-router-dom";
import {
  getAllBlogs,
  getBolgByUserId,
} from "../../../redux/action/BlogsAction";
import { useDispatch, useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

const Blogs = () => {
  const dispatch = useDispatch();
  // const { allblogs, loading } = useSelector((state) => state.ProductCategory);
  const { blogsByUser, loading } = useSelector((state) => state.blogByUserId);
  console.log(blogsByUser);
  const { user } = useSelector((state) => state.user);
  let users = user?._id;

  useEffect(() => {
    dispatch(getAllBlogs());
    dispatch(getBolgByUserId(users));
  }, [dispatch, users]);

  return (
    <div className="flex">
      <SideBar />
      <div className="p-5 w-full ">
        <div className="space-x-4 w-full flex items-center justify-between">
          <Link
            to="/dashboard/blogs/create"
            className="bg-gray pl-20 pr-20 pt-5 pb-5 shadow rounded"
          >
            Create Blogs
          </Link>
          <div class="pb-4 bg-white dark:bg-gray-900">
            <label for="table-search" class="sr-only">
              Search
            </label>
            <div class="relative mt-1">
              <div class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                class="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search for items"
              />
            </div>
          </div>
        </div>
        {/* <Link
            to="/dashboard/blogs/allblogs"
            className="bg-gray pl-20 pr-20 pt-5 pb-5 shadow rounded"
          >
            All Blogs
          </Link> */}
        <div className="w-full mt-10 overflow-x-scroll flex-wrap">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {/* <th scope="col" className="p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label for="checkbox-all-search" className="sr-only">
                          checkbox
                        </label>
                      </div>
                    </th> */}
                  <th scope="col" className="px-6 py-3">
                    Blog Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Publish date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <h1>Loading...</h1>
                ) : (
                  blogsByUser &&
                  blogsByUser.map((blog) => {
                    return (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                        >
                          {blog.title}
                        </th>
                        <td className="px-6 py-4">
                          {new Date(blog.createdAt)
                            .toISOString()
                            .substring(0, 10)}
                        </td>
                        <td className="px-6 py-4">{blog.category.name}</td>
                        <td className="px-6 py-4">
                          <Link
                            to={`/dashboard/blogs/${blog._id}/${blog.title}`}
                          >
                            <FiEdit size={20} color="green" />
                          </Link>
                        </td>
                        <td className="px-6 py-4">
                          <button className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                            <MdDeleteForever size={25} color="red" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* <Link to="/dashboard/blogs/create"></Link> */}
      </div>
    </div>
  );
};

export default Blogs;
