import React from 'react'

const Mangalam = () => {
  return (
    <div className='p-5 sm:p-10 font-Montserrat space-y-2 bg-white text-black'>
        <p className='font-medium text-base'>Mangalam Industrial Finance Limited (MIFL) is an RBI-registered non-banking financial company, founded in 1983. The company specializes in providing quick and hassle-free loans, particularly for electric two-wheelers, promoting sustainable transport solutions. Known for its customer-centric approach, MIFL offers simplified loan applications with minimal documentation and fast approvals. Leveraging technology to streamline services, MIFL ensures transparency throughout the loan process. With over 40 years of experience, the company is committed to delivering expert financial solutions and supporting India's green transportation initiatives.For more, visit MIFL’s <a className="ml-1 text-red font-semibold" href="www.miflindia.com">www.miflindia.com</a></p>
    </div>
  )
}

export default Mangalam