import React, { useEffect } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSocialById } from "../../../redux/action/socialAction";

const VolunteerCard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { socialId, loading } = useSelector((state) => state.createSocial);

  useEffect(() => {
    dispatch(getSocialById(id));
  }, [dispatch, id]);

  return (
    <div className="flex items-center flex-col justify-center bg-white text-black flex-wrap p-5 font-OpenSans bg-white text-black">
      <div className="mt-5">
        <h1 className="font-semibold text-3xl text-center uppercase">
          {socialId?.heading}
        </h1>
      </div>
      <div className=" mt-5">
        {loading ? (
          <h1>Loding...</h1>
        ) : (
          <div className="flex flex-wrap items-center justify-center">
            <Gallery>
              {socialId?.images && socialId?.images.length > 0 ? (
                socialId?.images.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-wrap items-center justify-center"
                    >
                      <Item
                        key={index}
                        original={item.url}
                        thumbnail={item.url}
                        width="820"
                        height="520"
                      >
                        {({ ref, open }) => (
                          <img
                            className="w-[380px] m-2 rounded"
                            ref={ref}
                            onClick={open}
                            src={item.url}
                            alt={`Blood donation camp ${index}`}
                          />
                        )}
                      </Item>
                    </div>
                  );
                })
              ) : (
                <h1>No data available for the selected year</h1>
              )}
            </Gallery>
          </div>
        )}
      </div>

      {/* <Gallery>
        {filterData.blodDonation &&
          filterData.blodDonation.map((item) => {
            console.log(item)
            return (
              <Item
                original={item.url}
                thumbnail={item.url}
                width="1080"
                height="720"
              >
                {({ ref, open }) => (
                  <img
                    className="w-[200px] m-4"
                    ref={ref}
                    onClick={open}
                    src={item.url}
                  />
                )}
              </Item>
            );
          })}
      </Gallery> */}
    </div>
  );
};

export default VolunteerCard;
