import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCarrerByid } from "../../../redux/action/carresAction";
import SideBar from "./SideBar";

const ViewYourApplication = () => {
  const dispatch = useDispatch();
  const { carrerId, loading } = useSelector((state) => state.carrerId);
  // const { user } = useSelector((state) => state.user);
  // console.log(user);
  const { id } = useParams();
  // console.log(carrerId);

  useEffect(() => {
    dispatch(getCarrerByid(id));
  }, [dispatch, id]);
  return (
    <div className="flex w-full font-Montserrat bg-white text-black">
      <main className="flex flex-col sm:flex-row w-full">
        <SideBar />
        <div className="sm:ml-3  sm:min-h-screen  w-full flex flex-col sm:pl-5 pt-10 pb-10">
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <div className="mt-10  sm:ml-3 sm:pl-5">
              <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Applied -
                  <span className="ml-5">
                    {new Date(carrerId?.createdAt).toLocaleDateString()}
                  </span>
                </h3>
                <p className="mt-1  font-semibold text-lg ">My Information</p>
              </div>
              <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium  text-gray-900">
                      First Name
                    </dt>
                    <dd className="mt-1 text-sm  text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.firstName}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium  text-gray-900">
                      Last Name
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.LastName}
                    </dd>
                  </div>

                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.email}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Mobile Number
                    </dt>
                    <dd className=" text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.Mobile}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Current CTC
                    </dt>
                    <dd className=" text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.CurrentCTC} LPA
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Expected CTC
                    </dt>
                    <dd className=" text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.ExpectedCTC} LPA
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Notice
                    </dt>
                    <dd className=" text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.Notice || 0} days
                    </dd>
                  </div>
                  <h1 className="pt-3 mb-3 font-semibold text-lg pl-3 sm:pl-0">
                    Work Experience
                  </h1>
                  {carrerId?.Employer.map((emp) => {
                    return (
                      <>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            Title
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {emp?.designation}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            company Name
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {emp?.companyName}
                          </dd>
                        </div>
                        <div className="flex flex-col sm:flex-row pt-5 pb-5 pl-3 sm:pl-0">
                          <dt className="text-lg font-medium leading-6 text-gray-900">
                            Summary
                          </dt>
                          <dt className=" h-auto w-full pl-0 sm:pl-3 pr-3 pt-3 pb-3 font-medium text-sm">
                            {emp?.summary}
                          </dt>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            From
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {emp?.startDate}
                          </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-gray-900">
                            To
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {emp.check === true ? (
                              <h1>Currently working</h1>
                            ) : (
                              emp?.endDate
                            )}
                          </dd>
                        </div>
                      </>
                    );
                  })}

                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">
                      Skills
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.skills}
                    </dd>
                  </div>
                  <div className="flex pt-5 pb-5 pl-3 sm:pl-0">
                    <dt className="text-lg font-medium leading-6 text-gray-900 capitalize">
                      candidate attachments
                    </dt>
                  </div>

                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 w-full flex-wrap">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Resume
                    </dt>
                    <dd className="mt-1 flex-wrap text-red text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <a href={carrerId?.resume.url}>{carrerId?.resume.url}</a>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Social Profile
                    </dt>
                    <dd className="mt-1 flex-wrap flex flex-col text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {carrerId?.socialProfile.map((url,index) => {
                        return (
                          <a key={index}
                            href={url}
                            className="text-red"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {url}
                          </a>
                        );
                      })}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ViewYourApplication;
