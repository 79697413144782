import React from "react";
import Graph from "../../image/Garudayaan/past.jpg";

const TimeLine = () => {
  return (
    <div className="text-black bg-white pl-3 sm:pl-10 pr-3 sm:pr-10 pt-5 sm:pt-10 pb-10 font-Montserrat space-y-4">
      <img src={Graph} alt="" />
      <p className="font-medium text-[12px] sm:text-base">
        Over the past five years, the company has achieved remarkable growth,
        steadily increasing its financial performance and market presence. This
        period has been defined by a series of strategic decisions that have
        driven expansion, including innovations in product offerings, a focus on
        quality, and a strong emphasis on customer satisfaction. The company
        successfully navigated market challenges and capitalized on
        opportunities, resulting in a consistent upward trajectory.
      </p>
      <p className="font-medium text-[12px] sm:text-base">
        This growth reflects a commitment to excellence, with the company
        gaining trust from its customers and strengthening its brand reputation.
        The past five years have laid a solid foundation for future success,
        marked by operational improvements, increased market penetration, and a
        growing customer base. Overall, this period highlights the company’s
        ability to thrive in a competitive landscape through continuous
        innovation and dedication to its goals.
      </p>
    </div>
  );
};

export default TimeLine;
