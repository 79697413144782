import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarrerAppByUserId } from "../../../redux/action/authAction";
import { Link } from "react-router-dom";
import SideBar from "./SideBar";

const CarrerUserProfile = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const { userCarrerProfile } = useSelector((state) => state.userCarrerProfile);
  // console.log(userCarrerProfile);

  useEffect(() => {
    dispatch(getCarrerAppByUserId());
  }, [dispatch]);
  return (
    <div className="w-full bg-white text-black font-Montserrat">
      {isAuthenticated ? (
        <main className="flex flex-col sm:flex-row w-full">
          <SideBar />
          <div className="flex min-h-screen overflow-y-scroll w-full sm:ml-3 shadow">
            <div className=" w-full h-full">
              <div className="relative overflow-x-auto  pb-10">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    All Applications
                  </caption>
                  <thead className="text-xs  text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-2 sm:px-6 py-3">
                        Job Title
                      </th>
                      <th scope="col" className="px-2 sm:px-6 py-3">
                        My Application Status
                      </th>
                      <th scope="col" className="px-2 sm:px-6 py-3">
                        Date Submited
                      </th>
                      <th scope="col" className="px-2 sm:px-6 py-3">
                        View Application
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userCarrerProfile?.length > 0 ? (
                      userCarrerProfile &&
                      userCarrerProfile.map((item) => {
                        return (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th
                              scope="row"
                              className="px-3 sm:px-6 py-4 font-medium text-gray-900  text-xs dark:text-white capitalize"
                            >
                              {item?.jobrole?.name}
                            </th>
                            <td
                              className="px-6 py-4 font-medium text-xs"
                              style={{
                                color: (item.status === 'shortlisted' || item.status === 'Under Review')  ? "green" : "red",
                              }}
                            >
                              {item.status}
                            </td>
                            <td className="px-6 py-4 text-xs">
                              {new Date(item.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              <Link
                                to={`/profile/application/${item._id}`}
                                className="px-6 py-4 text-xs font-semibold text-base text-[#9F2725] "
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <div className="pl-10 pr-10 pt-5 w-full">
                        <h1 className="text-[5px] sm:text-base">No data found for this applicant.</h1>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <div>Hello</div>
      )}
    </div>
  );
};

export default CarrerUserProfile;
