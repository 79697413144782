import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSocialAction } from "../../redux/action/socialAction";

const HealthInitiatives = () => {
  const dispatch = useDispatch();

  const { allSocial,loading } = useSelector((state) => state.createSocial);

  useEffect(() => {
    dispatch(getAllSocialAction());
  }, [dispatch]);

  return (
    <div className="pl-3 sm:pl-10 pr-3 sm:pr-10 pt-10  pb-10 min-h-screen  font-OpenSans bg-white text-black">
      {/* {HealthData &&
        HealthData.map((item) => {
          return (
            <NavLink to={`/health-initiatives/${item.head.years}`} className="shadow-xl rounded">
               <h1 className="font-semibold text-base uppercase ">{item.head.des} ({item.head.years})</h1>
              <img className="w-[300px] rounded" src={item.head.url} alt={item.head.des} />
             
            </NavLink>
          );
        })} */}
      {loading ? (
        <h1>Loading ...</h1>
      ) : (
        <div className="flex ">
          {allSocial &&
            allSocial.map((item) => {
              // console.log(item);
              if(item.category.name === "health initiatives"){
              return (
                <NavLink
                  to={`/health-initiatives/${item._id}`}
                  className="shadow-xl rounded w-1/2 sm:w-auto m-2 sm:m-0"
                >
                  <h1 className="font-semibold text-xs sm:text-base uppercase ">
                    {item.name} ({new Date(item.years).getFullYear()})
                  </h1>
                  <img
                    className="w-[200px] sm:w-[300px] h-full sm:h-full rounded"
                    src={item.images[0].url}
                    alt={item.des}
                  />
                </NavLink>
              )
            }
            return ''
            })}
        </div>
      )}
    </div>
  );
};

export default HealthInitiatives;
