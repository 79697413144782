import React from "react";
import { Link, NavLink } from "react-router-dom";
import headbanner from "../../image/homebanner.jpg";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { DataObj } from "./HeaderObj";

const Header = () => {
  return (
    <div className="bg-white">
      <div className="absolute top-[160px]  z-10 left-[-55px] bg-white news rounded-b bg-white">
        <Link className="hidden sm:block pl-2 pr-2 capitalize" to="/blogs">
          Blogs & updates
        </Link>
      </div>
      <div className="w-full relative flex flex-col md:flex-row items-center justify-center mb-5 sm:mb-10 md:mb-[200px]">
        <div className="w-full relative">
          <img className="md:h-[300px] w-full" src={headbanner} alt="" />
          <div className="w-full md:h-[300px] bg-[#28282853] absolute top-0 left-0 flex justify-center">
            <h1 className="font-semibold capitalize text-[9px] md:text-xl text-white text-center  md:w-3/6 xl:w-full">
              Our corporate ethos, akin to the eagle's prowess, is defined by
              resilience, innovation, and a relentless pursuit of excellence
            </h1>
          </div>
        </div>

        <div className="md:absolute md:bottom-[-150px] flex  md:flex-row items-center justify-center space-x-[5px] md:space-x-8 pl-3 sm:pl-0 pr-3 sm:pr-0 pt-5">
          {DataObj &&
            DataObj.map((item) => {
              return (
                <NavLink
                  key={item.id}
                  to={item.route}
                  className="hover:scale-105 relative transition duration-700 ease-in-out w-[80px] sm:w-[250px] md:w-[290px] h-[110px]  sm:h-[200px] md:h-[220px]  flex flex-col p-1 md:p-2 bg-white shadow-xl rounded-lg "
                  // style={{
                  //   backgroundImage: `url(${Appreciation})`,
                  //   backgroundPosition: "center",
                  //   backgroundSize: "cover",
                  // }}
                >
                  <img
                    className="w-20 h-20 rounded-full  hidden md:block  md:absolute top-[-40px] bg-white"
                    src={item.image}
                    alt="logo"
                  />
                  <div className="flex flex-col items-start justify-between  md:pt-8 w-full sm:w-auto h-full sm:h-auto space-y-0 sm:space-y-3">
                    <h1 className="font-semibold text-[13px] md:text-2xl">
                      {item.heading}
                    </h1>
                    <p className="font-medium text-[7px] md:text-sm leading-[8px]">
                      {item.details}
                    </p>
                    <button className="flex items-center font-medium text-[8px] md:text-xs capitalize underline">
                      know more
                      <span className="text-[#9F2725] ml-2">
                        <FaRegArrowAltCircleRight />
                      </span>
                    </button>
                  </div>
                </NavLink>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Header;
