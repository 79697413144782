import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogDetailsByID } from "../../redux/action/BlogsAction";
import { useParams } from "react-router-dom";
// import banner from "../../image/Home Banner 1.png";

const BlogDetails = () => {
  const dispatch = useDispatch();
  const { title } = useParams();
  const { blogId, loading } = useSelector((state) => state.ProductCategory);
  // console.log(blogId?.
  //   content);
  // const addSpacingToHtmlContent = (html) => {
  //   // Example: Add margin to paragraphs
  //   return html
  //     ?.replace(/<li>/g, '<li style="margin: 10px 0;">') 
  //     .replace(/<p>/g, '<p style="margin: 15px 0;">') 
  //     .replace(/<h([1-6])>/g, '<h$1 style="margin: 20px 0;">') 
  //     .replace(/<\/li>/g, "</li>") 
  //     .replace(/<\/p>/g, "</p>") 
  //     .replace(/<\/h([1-6])>/g, "</h$1>");
  // };

  useEffect(() => {
    dispatch(getBlogDetailsByID(title));
  }, [dispatch, title]);

  return (
    <div className="font-Montserrat bg-white text-black">
      {loading ? (
        <h1>Loading ...</h1>
      ) : (
        <div>
          <div
            className="relative w-full h-[200px] sm:h-[400px]  flex items-center"
            style={{
              backgroundImage: `url(${blogId?.image.url})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backfaceVisibility: "visible",
            }}
          >
            {/* <img src={require("../../image/Home Banner 1.png")} alt="" /> */}
            <div className="absolute left-5  sm:left-10 sm:w-3/6 flex flex-col">
              <h1 className="font-bold  sm:text-4xl capitalize  text-white  leading-tight">
                {blogId?.title}
              </h1>
              <h1 className="font-semibold sm:text-2xl text-white mt-2">
                {new Date(blogId?.createdAt).toLocaleDateString()}
              </h1>
            </div>
          </div>

          <div>{/* <h1>Home </h1> */}</div>

          <div className="w-full sm:p-10">
            <p className="text-gray-700 text-xs sm:text-base font-medium pl-5 pr-5 pb-3 mt-5">
              <div
                style={{
                  fontFamily: "Montserrat",
 
                }}
                dangerouslySetInnerHTML={{ __html: blogId && blogId.content }}

              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogDetails;
