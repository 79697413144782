import axios from "axios";
import {
  GET_ALL_PRODUCT_CATEGORY_REQUEST,
  GET_ALL_PRODUCT_CATEGORY_SUCCESS,
  GET_ALL_PRODUCT_CATEGORY_FAIL,
  GET_ALL_BLOGS_FAIL,
  GET_ALL_BLOGS_REQUEST,
  GET_ALL_BLOGS_SUCCESS,
  GET_BLOG_DETAILS_BY_ID_FAIL,
  GET_BLOG_DETAILS_BY_ID_REQUEST,
  GET_BLOG_DETAILS_BY_ID_SUCCESS,
  CREATE_BLOG_FAIL,
  CREATE_BLOG_REQUEST,
  CREATE_BLOG_SUCCESS,
  UPDATE_BLOG_FAIL,
  UPDATE_BLOG_REQUEST,
  UPDATE_BLOG_SUCCESS,
  GET_BOG_BY_USER_ID_REQUEST,
  GET_BOG_BY_USER_ID_SUCCESS,
  GET_BOG_BY_USER_ID_FAIL,
  UPLOAD_BLOG_IMAGE_FAIL,
  UPLOAD_BLOG_IMAGE_REQUEST,
  UPLOAD_BLOG_IMAGE_SUCCESS,
} from "../constants/BlogsConstants";

export const createBlogs = (postData) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_BLOG_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post("/api/blogs/create", postData, config);

    dispatch({
      type: CREATE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_BLOG_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update blog actions
export const updateBlogAction = (id, updateData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_BLOG_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.patch(
      `/api/blogs/update-blog/${id}`,
      updateData,
      config
    );

    dispatch({
      type: UPDATE_BLOG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BLOG_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllProductCategoryAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PRODUCT_CATEGORY_REQUEST,
    });

    const { data } = await axios.get("/api/product/product-category");
    dispatch({
      type: GET_ALL_PRODUCT_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRODUCT_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllBlogs = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BLOGS_REQUEST,
    });

    const { data } = await axios.get("/api/blogs/allblogs");
    dispatch({
      type: GET_ALL_BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BLOGS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getBlogDetailsByID = (title) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BLOG_DETAILS_BY_ID_REQUEST,
    });
    const { data } = await axios.get(`/api/blogs/${title}`);
    dispatch({
      type: GET_BLOG_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BLOG_DETAILS_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const getBolgByUserId = (id) =>async (dispatch)=>{
  try {
    dispatch({
      type: GET_BOG_BY_USER_ID_REQUEST
    })

    const {data} = await axios.get(`/api/blogs/blogs-by-user/${id}`)
    console.log(data)

    dispatch({
      type: GET_BOG_BY_USER_ID_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: GET_BOG_BY_USER_ID_FAIL,
      payload: error.response.data.message
    })
  }
}

export const uploadBlogImage = (image) =>async(dispatch)=>{
  try {
    dispatch({
      type: UPLOAD_BLOG_IMAGE_REQUEST
    })

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const {data} = await axios.post("/api/blogs/upload",image,config)
    dispatch({
      type: UPLOAD_BLOG_IMAGE_SUCCESS,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: UPLOAD_BLOG_IMAGE_FAIL,
      payload: error.response.data.message
      
    })
  }
}