import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../image/garuda logog.png";
import { MdOutlineClose } from "react-icons/md";
import facebook from "../../image/Blod donation/facebook.jpg";
import twitter from "../../image/Blod donation/twitter.jpg";
import linkedin from "../../image/Blod donation/linkedin.jpg";
import instagram from "../../image/Blod donation/instagram.jpg";
import youtube from "../../image/Blod donation/youtube.jpg";
import { FiPlus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

const Navbar = ({ setHamberger, hamberger }) => {
  const [toggle, setToggle] = useState(false);
  const [socialToggel, setSocialToggle] = useState(false);
  return (
    <div className="w-screen h-screen absolute z-[] top-0 right-0 flex font-roboto bg-white text-black">
      <div
        className=" sm:w-full bg-lightblack"
        onClick={() => setHamberger(!hamberger)}
      ></div>
      <div className="bg-white w-full sm:w-[500px] h-full pt-[2rem] pb-5 pl-5 relative">
        <div
          className="absolute right-8 top-10 border-2 rounded-full opacity-70"
          onClick={() => setHamberger(!hamberger)}
        >
          <MdOutlineClose size={22} />
        </div>
        <div className="navbar-start w-full">
          <Link
            to="/"
            className="logo flex items-center normal-case text-xl border-none"
          >
            <img className="h-10" src={logo} alt="" />
          </Link>
        </div>
        <div className="navbar-center  flex flex-col  font-roboto opacity-75">
          <ul className=" font-medium text-sm mt-5 space-y-3 uppercase ml-2 mr-[2rem] mb-5">
            <li className="border-b-[1.5px] ">
               <Link onClick={() => setHamberger(!hamberger)} to="corporate" className="text-xs">
                corporate
              </Link>
            </li>

            <li className="border-b-[1.5px]">
              <div className="flex  items-center justify-between  ">
                 <Link onClick={() => setHamberger(!hamberger)} to="ebikes" className="text-sm">
                  <span className="text-xs">Multi Steps</span>
                </Link>
                <div
                  className="mr-5 border mb-2 rounded"
                  onClick={() => setToggle(!toggle)}
                >
                  {toggle ? <IoMdClose size={18} /> : <FiPlus size={18} />}
                </div>
              </div>
              <div>
                {toggle ? (
                  <div>
                    <div className=""></div>
                    <li className="border-b-[1.5px] mt-2 text-xs pb-2">
                      <Link
                        onClick={() => setHamberger(!hamberger)}
                        to="/garuda-vega"
                      >
                        garudavega business solutions private limited
                      </Link>
                    </li>
                    <li className="border-b-[1.5px] mt-2 text-xs pb-2">
                      <Link
                        onClick={() => setHamberger(!hamberger)}
                        to="/ward-wizard"
                      >
                        wardwizard innovations & mobility limited
                      </Link>
                    </li>
                    <li className="border-b-[1.5px] mt-2 text-xs pb-2">
                      <Link onClick={() => setHamberger(!hamberger)} to="/Mangalam-Industrial-Finance-Limited">
                        Mangalam infrastructure private limited
                      </Link>
                    </li>
                    <li className=" mt-2 text-xs pb-2">
                      <Link
                        onClick={() => setHamberger(!hamberger)}
                        to="/garudayaan"
                      >
                        garudayaan
                      </Link>
                    </li>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </li>

            <li className="border-b-[1.5px] text-xs pb-2">
               <Link
                onClick={() => setHamberger(!hamberger)}
                to="gplus-premium-appliances"
              >
                Consumer Electronics
              </Link>
            </li>

            <li className="border-b-[1.5px] text-xs pb-2 ">
              <Link onClick={() => setHamberger(!hamberger)} to="ebikes">
                Joy e-bike
              </Link>
            </li>

            <li className=" ">
              <div className="flex  items-center justify-between border-b-[1.5px] text-xs">
                 <li onClick={() => setHamberger(!hamberger)}>
                  Societal contributions
                </li>
                <div
                  className="mr-5 border mb-2 rounded"
                  onClick={() => setSocialToggle(!socialToggel)}
                >
                  {socialToggel ? (
                    <IoMdClose size={18} />
                  ) : (
                    <FiPlus size={18} />
                  )}
                </div>
              </div>
              <div>
                {socialToggel ? (
                  <div>
                    <div className=""></div>
                    <li className="border-b-[1.5px] text-xs mt-2 pb-2">
                      <Link
                        onClick={() => setHamberger(!hamberger)}
                        to="/health-initiatives"
                      >
                        health initiatives
                      </Link>
                    </li>
                    <li className="border-b-[1.5px] text-xs mt-2 pb-2">
                      <Link
                        onClick={() => setHamberger(!hamberger)}
                        to="/environmental-sustainability"
                      >
                        environmental sustainability
                      </Link>
                    </li>
                    <li className="border-b-[1.5px] text-xs mt-2 pb-2">
                      <Link
                        onClick={() => setHamberger(!hamberger)}
                        to="/volunteer-programs"
                      >
                        volunteer programs
                      </Link>
                    </li>
                    <li className="border-b-[1.5px] text-xs mt-2 pb-2">
                      <Link
                        onClick={() => setHamberger(!hamberger)}
                        to="/Charitable Donations"
                      >
                        Charitable Donations
                      </Link>
                    </li>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </li>

            <li className="border-b-[1.5px] text-xs pb-2 ">
              <Link onClick={() => setHamberger(!hamberger)} to="/carrers">
                Carrer
              </Link>
            </li>

            <li className=" text-sm">
              <Link onClick={() => setHamberger(!hamberger)} to="contact" className="text-xs">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className=" ">
          <h1 className="capitalize font-medium text-base ">Follow Us:</h1>
          <div className="flex flex-row  space-x-3 mt-5">
            <a
              href="https://www.facebook.com/GarudamartindiapvtLtd"
              className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-8 sm:w-[60px]" src={facebook} alt="facebook" />
            </a>

            <a
              href="https://www.instagram.com/garuda_mart_india/"
              className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-8 sm:w-[60px]" src={instagram} alt="twitter" />
            </a>
            <a
              href="https://www.youtube.com/@GarudaMartIndiaPvtLtd"
              className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-8 sm:w-[60px]" src={youtube} alt="facebook" />
            </a>
            <a
              href="https://twitter.com/GarudaMartIndia"
              className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-8 sm:w-[60px]" src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/garuda-mart-india-private-limited/"
              className="w-10 sm:w-auto h-10 sm:h-auto  m-1  rounded-full    shadow-stone-500 cursor-pointer flex items-center justify-center"
            >
              <img className="w-8 sm:w-[60px]" src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
