import React, { useEffect } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
// import { HealthData } from "./HealthObj";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSocialById } from "../../redux/action/socialAction";


const HealthCard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { socialId,loading } = useSelector((state) => state.createSocial);

  useEffect(()=>{
    dispatch(getSocialById(id))
  },[dispatch,id])

  return (
    <div className="flex flex-col items-center bg-white text-black justify-center flex-wrap p-5 font-OpenSans">
      <div className="mt-5">
        <h1 className="font-semibold text-3xl text-center uppercase">
          {socialId?.heading}
        </h1>
      </div>
      <div className="flex flex-wrap  items-center justify-center mt-5">
        {
          loading ? <h1>Loding...</h1>: 
          (
            <Gallery>
            {socialId?.images && socialId?.images.length > 0 ? (
              socialId?.images.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-wrap  items-center justify-center"
                  >
                    {/* <h1>{item.head.title}</h1>
                    {item.blodDonation &&
                      item.blodDonation.map((blod, i) => ( */}
                        <Item
                          key={index}
                          original={item.url}
                          thumbnail={item.url}
                          width="1080"
                          height="720"
                        >
                          {({ ref, open }) => (
                            <img
                              className="w-[380px] m-2 rounded"
                              ref={ref}
                              onClick={open}
                              src={item.url}
                              alt={`Blood donation camp ${index}`}
                            />
                          )}
                        </Item>
                      {/* ))} */}
                  </div>
                );
              })
            ) : (
              <h1>No data available for the selected year</h1>
            )}
          </Gallery>

          )
        }
       
      </div>

    
    </div>
  );
};

export default HealthCard;
