import React from "react";
import { Journal } from "./JournalsObj";
import { NavLink } from "react-router-dom";
import "./journals.css";

const Journals = () => {
  return (
    <div className="p-3 sm:p-10 font-OpenSans bg-white text-black">
      <div className="flex flex-col items-center justify-center">
        <h1 className="font-semibold text-base sm:text-3xl text-center uppercase mt-5">
          Featured Publications
        </h1>
        <p className="font-medium text-xs sm:text-base">
          Showcasing Our Journey and Vision
        </p>
      </div>
      <div className="flex items-center flex-wrap sm:flex-nowrap justify-between space-x-0 sm:space-x-10 pb-20 pt-[30px]">
        {Journal &&
          Journal.map((item) => {
            return (
              <div className="mb-5 sm:mb-0 w-[100px] sm:w-[375px] ">
                <div className="w-full flex items-center justify-center pt-2 pb-2">
                  <h1 className="font-semibold text-sm sm:text-lg capitalize  magzineitem">
                    {item.name}
                  </h1>
                </div>
                {/* <Link
                 
                  className=" magzine"
                > */}
                <img className="w-full" src={item?.image} alt="" />
                {/* </Link> */}
                <div className="flex items-center justify-between mt-0 sm:mt-3 w-full  xl:space-x-[90px]">
                  <NavLink className="" to={item?.url}>
                    <button className="font-medium text-[7px] sm:text-xs   uppercase bg-[#9F2725] text-white rounded-full pl-1.5 sm:pl-3 pr-1.5 sm:pr-3 pt-1 pb-1">
                      Go to site
                    </button>
                  </NavLink>
                  <NavLink className="" to={`/journal/${item?.id}`}>
                    <button className="font-medium text-[7px] sm:text-xs  uppercase bg-[#9F2725] text-white rounded-full pl-1.5 sm:pl-3 pr-1.5 sm:pr-3 pt-1 pb-1">
                      Read now
                    </button>
                  </NavLink>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Journals;
