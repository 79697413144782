import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllJobCategory,
  getAllPositionAction,
} from "../../redux/action/carresAction";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoBagOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FaBookOpen } from "react-icons/fa";
// import Search from "./Profile/search";

const Carrers = () => {
  const dispatch = useDispatch();
  const { allposition, alljobcategory ,loading} = useSelector(
    (state) => state.jobPosition
  );
  const [check, setChecked] = useState();
  const [joTitle, setJobTittle] = useState("");
  // const [FilterJob, setFilterJob] = useState(allposition);
  // console.log(allposition);
  // console.log(alljobcategory);
  // console.log(check);

  // const truncateText = (text, wordLimit) => {
  //   if (Array.isArray(text)) {
  //     text = text.join(" ");
  //   } else if (typeof text !== "string") {
  //     return "";
  //   }
  //   const words = text.split(" ");
  //   if (words.length > wordLimit) {
  //     return words.slice(0, wordLimit).join(" ") + "...";
  //   }
  //   return text;
  // };

  const truncateContent = (htmlString, wordLimit) => {
    const textContent = htmlString.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const words = textContent.split(/\s+/); // Split by whitespace
    return words.slice(0, wordLimit).join(' ') + (words.length > wordLimit ? '...' : '');
  };
  // const onHandleSubmit = (e) => {
  // e.preventDefault();

  // };

  // useEffect(() => {
    // const filterSearch =
    //   allposition &&
    //   allposition.filter((search) => {
    //     console.log(search)
    //     return (
    //       search?.name?.toLowerCase().includes(joTitle?.toLowerCase()) ||
    //       search?.location?.toLowerCase().includes(joTitle?.toLowerCase()) ||
    //       search?.department?._id.toLowerCase().includes(check?.toLowerCase())
    //     );
    //   });

      // const filterByCat = allposition && allposition.filter((cat)=>cat.department?._id === check)

    // setFilterJob(filterSearch);
    // setFilterJob(filterByCat)
  // }, [joTitle, allposition,check,loading]);

  useEffect(() => {
    dispatch(getAllPositionAction(joTitle.toLocaleLowerCase()));
    dispatch(getAllJobCategory());
  }, [dispatch,joTitle,check]);

  return (
    <div className="font-Montserrat bg-white text-black">
      <div>
        <img src={require("../../image/2023/Untitled-3 (1).jpg")} alt="" />
      </div>

      <div className="flex  justify-center mt-10 pb-20 pl-2 pr-2 sm:pr-0 sm:pl-4 w-full">
        <div className="w-[300px] rounded p-1 hidden sm:block">
          <div>
            <h1 className="font-medium text-lg">All Filter</h1>
            <div className="border mt-1"></div>
          </div>
          <div className="w-full">
            <h1 className="font-medium text-base mt-1">Department</h1>
            <div className="w-full mt-1">
              {alljobcategory &&
                alljobcategory.map((cat, i) => {
                  return (
                    <div className="flex items-center mb-2 w-full">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value={cat.name}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={() => setChecked(cat._id)}
                      />
                      <label
                        for="default-checkbox"
                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {cat.name}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* <div className="mt-5">
          <Search />
        </div> */}
        <div className="w-full sm:w-6/12">
          <div className="ml-2">
            <h1 className="font-semibold text-lg">Current Opening</h1>

            <div class="w-8/12 flex space-x-2 mt-2">
              {/* <label
                for="default-search"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label> */}
              <div class="relative w-full">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Job title or skill"
                  onChange={(e) => setJobTittle(e.target.value)}
                />
              </div>

              {/* <button
                type="submit"
                class="text-white  bg-[#FF5798] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm pl-10 pr-10 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Search
              </button> */}
            </div>
          </div>

          <div className="w-full  space-y-2 mt-5">
            {loading ? (
              <h1>Loading...</h1>
            ) : ( allposition?.length > 0 ?
            allposition &&
              allposition.map((item) => {
                // console.log(item);
                // if (item.department?._id === check) {
                return (
                  <div className="pl-2 sm:pl-5 pr-2 sm:pr-5 pt-3 pb-3 border sm:border-none  hover:bg-gray hover:rounded rounded">
                  <NavLink
                    to={`/carrers/${item.name}/${item._id}`}
                  >
                    <h1 className="font-medium textxs sm:text-lg text-red">{item.name}</h1>
                    <h1 className="flex items-center">
                      <IoBagOutline />
                      <span className="ml-2">{item.experience}</span>
                    </h1>
                    <h1 className="flex items-center">
                      <FaMapMarkerAlt />
                      <span className="ml-2 text-sm sm:text-base">{item.location}</span>
                    </h1>
                    <p className="flex ">
                      <FaBookOpen size={18} />
                      <span className="ml-2 font-medium text-xs sm:text-sm">
                        {item?.details.map((con) => {
                          return (
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: truncateContent(con, 40)
                                
                              }}
                            />
                          );
                        })}
                      </span>
                    </p>
                    <div className="mt-1">
                      <p className="font-medium sm:font-semibold text-sm sm:text-sm">
                        Skills :
                        <span className="font-medium sm:font-semibold text-sm sm:text-sm ml-2">
                          {item.skills}
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                      <h1 className="font-medium text-xs sm:text-sm">
                        {new Date(item.createdAt).toLocaleDateString()} posted
                      </h1>

                      <div className="flex items-center space-x-2">
                        <h1 className="font-medium text-xs sm:text-sm">Openings</h1> <span>:</span>
                        <span className="font-medium text-xs sm:text-sm">{item.opening}</span>
                      </div>
                    </div>
                  </NavLink>
                  </div>
                );
              }) : <div>
                  No result found
                </div> )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrers;
