import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Pages from "./components/HomeAppliance/Pages";
import Contact from "./components/Contact/Contact";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/Footer/Footer";
// import Message from './components/message/Message';
import Ebikes from "./components/E_bikes/E_bikes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "photoswipe/dist/photoswipe.css";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsCondition from "./components/TermsCondition";
import Mihos from "./components/Bikes/Mihos/Mihos";
import Wolf from "./components/Bikes/Wolf/Wolf";
import Nanu from "./components/Bikes/Nanu/Nanu";
import Glob from "./components/Bikes/Glob/Glob";
import Wolfplus from "./components/Bikes/Wolfplus/Wolfplus";
import ScrollToTop from "./ScrollToTop";
import About from "./components/About/About.js";
import Expedition from "./components/Expedition/Expedition.js";
import Profile from "./components/Corporate/Company_profile/profile.js";
import BusinessOverview from "./components/Corporate/BusinessOverview/BusinessOverview.js";
import Journals from "./components/Journals/Journals.js";
import Pdfview from "./components/Journals/Pdfview.js";
import Corporates from "./components/Corporate/Corporates/Corporates.js";
import Appreciation from "./components/Appreciation/Appreciation.js";
import PremiumProduct from "./components/gplus-premium/premiumProduct.js";
import HealthInitiatives from "./components/societal contribution/health-initiatives.js";
import HealthCard from "./components/societal contribution/HealthCard.js";
import VolunteerCard from "./components/societal contribution/volunteer_programs/VolunteerCard.js";
import Carrers from "./components/Carrers/Carrers.js";
import JobDescription from "./components/Carrers/JobDescription.js";
import Login from "./components/Auth/Login.js";
import Register from "./components/Auth/Register.js";
import ProtectedRoutes from "./components/protectedRoutes/protectedRoutes.js";
import Dashboard from "./components/Admin/Dashboard/Dashboard.js"
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "./redux/action/authAction.js";
import AllUsers from "./components/Admin/AllUsers/AllUsers.js"
import AllApplications from "./components/Admin/AllCarrerApplication/AllApplications.js"
import AllJobs from "./components/Admin/AllJobs/AllJobs.js"
import GetApplicationById from "./components/Admin/AllCarrerApplication/GetApplicationById.js"
import UpdateJob from "./components/Admin/AllJobs/UpdateJob.js";
import CreateJob from "./components/Admin/AllJobs/CreateJob.js";
import store from "./store.js"
import CarrerUserProfile from "./components/Carrers/Profile/CarrerUserProfile.js";
import UserProfile from "./components/Carrers/Profile/UserProfile.js";
import ViewYourApplication from "./components/Carrers/Profile/ViewYourApplication.js";
import Blogs from "./components/Blogs/blogs.js"
import BlogsPage from "./components/Admin/Blogs/blogs.js"
import BlogsCreate from "./components/Admin/Blogs/BlogsCreate/BlogsCreate.js"
import UpdateBlog from "./components/Admin/Blogs/UpdateBlog/UpdateBlog.js"
import BlogDetails from "./components/Blogs/BlogDetails.js";
import SocialContribution from "./components/Admin/SocialContribution/SocialContribution.js"
import AllSocialProgram from "./components/Admin/AllSocialProgram/AllSocialProgram.js"
import AddMoreEvent from "./components/Admin/AllSocialProgram/AddMoreEvent/AddMoreEvent.js"
import UpdateSocialProgram from "./components/Admin/AllSocialProgram/UpdateSocialProgram.js";
import UpdateApplicationStatus from "./components/Admin/AllCarrerApplication/UpdateApplicationStatus.js";
import CreateDepartment from "./components/Admin/AllJobs/CreateDepartment.js";
import VolunteerPrograms from "./components/societal contribution/volunteer_programs/volunteer_programs.js";
import ForgotPassword from "./components/Auth/ForgotPassword.js";
import ResetPassword from "./components/Auth/ResetPassword.js";
import TimeLine from "./components/Graph/TimeLine.js";
import Future from "./components/Graph/Future.js";
import CharitableDonations from "./components/societal contribution/charitable-donations/charitable_donations.js";
import EnvironmentalSustainability from "./components/societal contribution/environmental-sustainability/Environmental_Sustainability.js";
import WardWizard from "./components/Multi_steps/ward-wizard/ward-wizard.js";
import Mangalam from "./components/Multi_steps/mangalam/mangalam.js";
import Garudayaan from "./components/Multi_steps/garudayaan/garudayaan.js";

function App() {
  const dispatch = useDispatch();

  useEffect(()=>{

    store.dispatch(loadUser())
  },[dispatch])



  return (
    <Fragment>
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/appliances" element={<Pages />} />
          <Route path="/ebikes" element={<Ebikes />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/expedition" element={<Expedition />} />

          <Route element={<ProtectedRoutes />}>
            <Route exect path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/allusers" element={<AllUsers />} />
            <Route path="/dashboard/all-application" element={<AllApplications />} />
            <Route path="/dashboard/all-application/:id" element={<GetApplicationById />} />
            <Route path="/dashboard/all-application/:update" element={<UpdateApplicationStatus />} />
            <Route path="/dashboard/all-jobs" element={<AllJobs />} />
            <Route path="/dashboard/all-jobs/:id" element={<UpdateJob />} />
            <Route path="/dashboard/create" element={<CreateJob />} />
            <Route path="/dashboard/department-type" element={<CreateDepartment />} />
            <Route path="/dashboard/blogs" element={<BlogsPage/>} />
            <Route path="/dashboard/blogs/create" element={<BlogsCreate/>} />
            <Route path="/dashboard/blogs/:id/:title" element={<UpdateBlog/>} />
            <Route path="/dashboard/social-contribution" element={<SocialContribution />} />
            <Route path="/dashboard/social-contribution/allSocial-program" element={<AllSocialProgram />} />
            <Route path="/dashboard/social-contribution/update-event/:id" element={<UpdateSocialProgram />} />
            <Route path="/dashboard/social-contribution/create-event" element={<AddMoreEvent />} />
            
          </Route>


          <Route path="/corporate/company-profile" element={<Profile />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/profile/application" element={<CarrerUserProfile />} />
          <Route path="/profile/application/:id" element={<ViewYourApplication />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:date/:title" element={<BlogDetails />} />
          <Route
            path="/corporate/business-overview"
            element={<BusinessOverview />}
          />
          <Route
            path="/gplus-premium-appliances"
            element={<PremiumProduct />}
          />
          <Route path="/health-initiatives" element={<HealthInitiatives />} />
          <Route path="/health-initiatives/:id" element={<HealthCard />} />
          <Route path="/volunteer-programs" element={<VolunteerPrograms />} />
          <Route path="/volunteer-programs/:id" element={<VolunteerCard />} />
          <Route path="/charitable-donations" element={<CharitableDonations />} /> 
          <Route path="/environmental-sustainability" element={<EnvironmentalSustainability />} />

          <Route path="/corporate" element={<Corporates />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/resetPassword/:id/:token" element={<ResetPassword />} />

          <Route path="/accomplishment-timeline" element={<TimeLine />} />
          <Route path="/future-graph" element={<Future />} />
          <Route path="/journal/:id" element={<Pdfview />} />
          <Route path="/appreciation" element={<Appreciation />} />
          <Route path="/journals" element={<Journals />} />
          <Route path="/carrers" element={<Carrers />} />
          <Route path="/carrers/:name/:id" element={<JobDescription />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/ward-wizard" element={<WardWizard />} />
          <Route path="/Mangalam-Industrial-Finance-Limited" element={<Mangalam />} />
          <Route path="/garudayaan" element={<Garudayaan />} />  
          <Route path="/mihos" element={<Mihos />} />
          <Route path="/wolfplus" element={<Wolfplus />} />
          <Route path="/nanu" element={<Nanu />} />
          <Route path="/glob" element={<Glob />} />
          <Route path="/wolf" element={<Wolf />} />
        </Routes>
      </ScrollToTop>
      {/* <Message /> */}
      <ToastContainer />
      <Footer />
    </Fragment>
  );
}

export default App;

// "homepage": "https://demo.garudamartindia.com/",
