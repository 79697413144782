import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllSocialAction } from '../../../redux/action/socialAction';
import { NavLink } from 'react-router-dom';

const EnvironmentalSustainability = () => {
    const dispatch = useDispatch()
    const { allSocial, loading } = useSelector((state) => state.createSocial);
    // console.log(allSocial)
    useEffect(() => {
      dispatch(getAllSocialAction());
    }, [dispatch]);
  
    return (
      <div>
        <div className="pl-3 sm:pl-10 pr-3 sm:pr-10 pt-10  pb-10 min-h-screen  font-OpenSans bg-white text-black">
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <div className="flex  sm:space-x-3">
              {allSocial &&
                allSocial.map((item) => {
                  if (item.category.name === "environmental sustainability") {
                    return (
                      <NavLink
                        to={`/environmental-sustainability/${item._id}`}
                        className="shadow-xl rounded w-1/2 sm:w-auto m-2 sm:m-0"
                      >
                        <h1 className="font-semibold text-xs sm:text-base uppercase">
                          {item.name} ({new Date(item.years).getFullYear()})
                        </h1>
                        <img
                          className="w-[200px] sm:w-[300px] h-full sm:h-full rounded"
                          src={item?.images[0]?.url}
                          alt={item.name}
                        />
                      </NavLink>
                    );
                  }
                  return null;
                })}
            </div>
          )}
        </div>
      </div>
    );
  };
  

export default EnvironmentalSustainability