import React from "react";
import BusinessOverview from "../BusinessOverview/BusinessOverview";
import Profile from "../Company_profile/profile";
import CorporateBanner from "../../../image/corporate_banners1.jpg";

const Corporates = () => {
  return (
    <div className="">
      <div className="w-full realtive font-OpenSans bg-white text-black">
        <div className="w-full h-[200px] md:h-auto">
        <img className="h-full w-full" src={CorporateBanner} alt="" />
        </div>
        <div className="absolute top-20 md:top-[100px] left-3  md:pr-0 md:left-10 space-y-3 w-auto md:w-6/12 ">
          <h1 className="font-bold text-white md:text-3xl capitalize">
            COMPANY OVERVIEW
          </h1>
          <p className="text-white font-medium text-xs md:text-base ">
            Garuda Mart India Pvt. Ltd. is a pioneering leader in home
            appliances, e-bikes, and logistics, renowned for quality,
            innovation, and customer satisfaction. Offering high-performance,
            energy-efficient products and cutting-edge e-bikes, Garuda Mart
            ensures seamless delivery through its efficient logistics services,
            solidifying its trusted reputation across India.
          </p>
          <button className="md:w-[200px] pl-5 pr-5 pt-2 pb-2 bg-[#9F2725] text-xs md:text-sm text-white rounded">
            Learn More
          </button>
        </div>
      </div>
      <BusinessOverview />
      <Profile />
    </div>
  );
};

export default Corporates;
