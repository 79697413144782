import React from "react";
// import {Link } from "react-router-dom"
// import Ebikes from "./E_bikesObj.js";
import JoyEbike from "../../image/joy-ebike1.jpg";

const E_bikes = () => {
  return (
    <div
      className="w-full flex flex-wrap items-center justify-center pt-10 pb-10 font-Montserrat bg-white text-black"
      id="ebikes"
    >
      <img className="" src={JoyEbike} alt="" />
      <div className="flex items-center justify-around sm:justify-between w-full sm:w-1/2 pl-3 md:pl-10 pr-3 md:pr-10 mt-5 sm:mt-0">
        <button className=" font-medium text-xs sm:text-lg bg-[#9F2725] pl-3 sm:pl-10 pr-3 sm:pr-10 pt-3 pb-3 rounded-full text-white">
          <a href="/contact"> Apply for Dealership </a>
        </button>
        <button className="  font-medium text-xs sm:text-lg bg-[#9F2725] pl-3 sm:pl-10 pr-3 sm:pr-10 pt-3 pb-3 rounded-full text-white">
          <a href="https://www.joyebike.com/" target="_blank"  rel="noreferrer">
            Go to website
          </a>
        </button>
      </div>

      <div className="pl-3 md:pl-10 pr-3 md:pr-10 mt-10 space-y-4">
        <p className="font-medium text-base">
          Garuda Mart India Pvt. Ltd. is proud to be the South India distributor
          for Joy E-bike, renowned for its innovative and eco-friendly electric
          bikes. Joy E-bike offers a diverse range of models, from high-speed
          urban commuters to stylish and efficient city rides, all featuring
          advanced lithium-ion batteries and smart technology for enhanced
          performance and convenience.
        </p>
        <p className="font-medium text-base">
          With Joy E-bike's commitment to sustainability and cutting-edge
          design, you can enjoy longer ranges, faster charging, and a greener
          commute. Each bike integrates seamlessly with user-friendly apps for
          real-time tracking and management, making your ride both efficient and
          enjoyable.
        </p>
        <p className="font-medium text-base">
          To explore the full range of Joy E-bike models and learn more about
          their features, visit the official website at{" "}
          <span className="text-green">
            <a className="text-red font-semibold" href="www.joyebike.com">
              www.joyebike.com
            </a>
          </span>
          . Discover how Garuda Mart India is making sustainable transportation
          accessible across South India.
        </p>
      </div>
      {/* {Ebikes.map((items, inedx) => {
        return (
          <div key={items.id} className="w-full sm:w-6/12 md:w-4/12 lg:w-5/12 m-2 shadow rounded-b-xl">
            <img
              className="w-full h-64 lg:h-96 rounded-t-xl"
              src={items.ebike}
              alt="ebikes"
            />
            <Link to="/contact">
                <button className="font-semibold  w-full rounded-b-xl  capitalize p-2 hover:bg-black hover:text-white">apply for dealership</button>
            </Link >
          </div>
        );
      })} */}
    </div>
  );
};

export default E_bikes;
